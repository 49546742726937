import { useGetClientsQuery } from "../redux/services/clients/clientsApi";
import { CheckboxList } from "./CheckboxList";
import { useState } from "react";
import { useDebounceSearch } from "../hooks";
import { Button, Input } from "../shared";

export const ClientsFilterList = (props) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { search, handleSearch: handleDebounceSearch } = useDebounceSearch();

  const { data: clients, isLoading } = useGetClientsQuery({
    page: pageIndex + 1,
    limit: pageSize,
    sortBy: "name",
    sortOrder: "asc",
    search: search.useSearch,
    searchTerm: search.term,
  });

  function handlePageChange(newPage) {
    setPagination((prev) => ({
      ...prev,
      pageIndex: newPage,
    }));
  }

  function handleSearch(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleDebounceSearch(value);
  }

  function handleChange(val) {
    const title =
      val.map(({ name }) => name).join(", ") || props.value?.defaultText;
    let value = val.map((client) => client._id);

    if (Array.isArray(value)) {
      if (value.length === 0) {
        value = null;
      } else {
        value = value.join(",");
      }
    }

    props.onChange({ title, value });
  }

  return (
    <div style={{ minWidth: "300px" }}>
      <Input
        type="text"
        placeholder="Search clients..."
        onChange={(e) => handleSearch(e.value)}
      />
      <CheckboxList
        objectKey="_id"
        value={props.value.value}
        onChange={handleChange}
        data={clients?.clients || []}
        loading={isLoading}
      />
      <div className="flex items-center justify-between mt-2">
        <Button
          onClick={() => handlePageChange(pageIndex - 1)}
          disabled={pageIndex === 0}
          type="light"
          size="xs"
        >
          Previous
        </Button>
        <span className="text-xs">Page {pageIndex + 1}</span>
        <Button
          onClick={() => handlePageChange(pageIndex + 1)}
          disabled={
            !clients?.clients?.length || clients?.clients?.length < pageSize
          }
          type="light"
          size="xs"
        >
          Next
        </Button>
      </div>
    </div>
  );
};
