import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../../shared/Spinner";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
// import { updateInvoice } from "../../../redux/reducers/invoices/invoiceSlice";
import { deleteInvoices } from "../../../redux/reducers/invoices/invoicesSlice";
import { formatDate } from "../../../utils";
import { FORM_OPTIONS } from "../../Jobs/table.config";
import Info from "./Info";
import DataTable from "./DataTable";
import InvoiceLayout from "../../../layouts/InvoiceLayout";
import InvoiceTotals from "./Totals";
import { SidebarContainer } from "../../../components";
import { Badge, EditableText } from "../../../shared";
import Tabs from "../../../shared/Tabs";
import { useCrumbs } from "../../../hooks/useCrumbs";
import {
  useDeleteInvoicesMutation,
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
} from "../../../redux/services/invoices/invoicesApi";
import { toast } from "react-toastify";
import { isAuthorized } from "../../../utils/authority";
import { useConfig } from "../../../hooks";
import { getPdfConfig } from "./table.configs/pdf";
import jsPDF from "jspdf";
import JobItemPdf, {
  generateWorkOrderPDF,
} from "../../../shared/PdfGenerator/jobItempdf";
import { format } from "date-fns";
import InvoiceGeneratorButton from "./InvoiceGeneratorButton";

const InvoiceShow = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { user } = useAuth();
  const crumbs = useCrumbs();
  const { appType } = useConfig();
  const [loading, setLoading] = useState("");

  const { form, clearForm, updateForm, setFormInit } = useForm(FORM_OPTIONS);
  const defaultConfig = {
    COLUMN_DEFINITIONS: [],
    TOTAL_COLUMN_DEFINITIONS: [],
  };
  const { pdfConfig = defaultConfig } = getPdfConfig(appType) || {};
  const columnDefs = pdfConfig?.COLUMN_DEFINITIONS || [];
  const totalColumnDefs = pdfConfig?.TOTAL_COLUMN_DEFINITIONS || [];

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  const [updateInvoice, { isLoading: invoiceUpdating }] =
    useUpdateInvoiceMutation();

  const {
    data: invoiceData,
    isLoading,
    ...rest
  } = useGetInvoiceQuery(
    {
      id: params.id,
      drivers: true,
    },
    { skip: !params.id }
  );

  const invoice = invoiceData || {};

  useEffect(() => {
    crumbs.setLoading(true);
  }, []);

  useEffect(() => {
    if (invoice._id) {
      crumbs.updateCrumbName({
        name: invoice.invoiceId,
        path: "/invoices/" + invoice._id,
      });
      setFormInit(invoice);
      crumbs.setLoading(false);
    }
  }, [invoice._id]);

  async function handleInvUpdate(e) {
    const update = {
      [e.id]: e.value,
      ...e.data,
    };

    const res = await updateInvoice({
      data: update,
      id: invoice._id,
    }).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "updated-invoice",
    });
  }

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 gap-4">
            {invoice._id && (
              <InvoiceLayout
                columns={columnDefs}
                totalColumns={totalColumnDefs}
                invoice={invoice}
                appType={appType}
              >
                <Info
                  clearForm={clearForm}
                  user={user}
                  form={form}
                  onFormChange={onFormChange}
                  info={invoice}
                  handleInvUpdate={handleInvUpdate}
                  appType={appType}
                />
                <InvoiceTotals form={form} info={invoice} appType={appType} />
              </InvoiceLayout>
            )}
            {invoice._id && (
              <>
                <DataTable data={invoice} appType={appType} />
              </>
            )}
          </div>
        </div>
      ),
    },
    // {
    //   id: "Edit",
    //   content: <InvoiceEdit user={user} invoice={invoice} />,
    // },
  ];

  function handleTabClick(_e, _item, path) {
    // location.hash = e.target.innerHTML;
    navigate(path);
  }

  function getDefaultTab() {
    if (location.hash) {
      const tabHash = location.hash.slice(1);
      return tabs.findIndex((tab) => tab.id === tabHash);
    }
    return;
  }

  const title = (
    <div className="flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="invoiceId"
        value={invoice.invoiceId}
        size="md"
        disabled={!isAuthorized("owner, admin")}
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        loading={invoiceUpdating}
        onSave={(e) => handleInvUpdate({ id: e.id, value: e.value })}
      />
      {invoice.status && (
        <Badge label={invoice.status} size="lg" className="ml-2" />
      )}
    </div>
  );

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderTabHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 text-gray-800 hidden">
              {headerProps.actions}
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerProps.actions}
        </div>
      </div>
    );
  };

  const actions = (
    <>
      <span className="mr-5">
        Created: {formatDate(invoice.dateCreated, null, true)}
      </span>
      <span>Updated: {formatDate(invoice.dateUpdated, null, true)}</span>
    </>
  );

  return (
    <SidebarContainer
      title={title}
      hideSidebar
      header={renderTabHeader}
      actions={actions}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 45}px`,
        };
        return (
          <div style={wrapperStyle} className="h-auto w-full">
            {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Tabs
                className={`w-full overflow-hidden`}
                style={wrapperStyle}
                panelStyle={panelStyle}
                useSearchParams
                panelClasses={`p-2 border-t overflow-y-scroll`}
                tabListClasses="bg-white px-2 h-[38px]"
                tabclassName="mx-1"
                id="job-show"
                onClick={handleTabClick}
                data={tabs}
              />
            )}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

export default InvoiceShow;
