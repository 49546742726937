import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../shared/Table/table.utils";
import { statusOptions } from "../../../constants/select";
import { formatDate, isNum, renderAmount } from "../../../utils";
import { Badge } from "../../../shared";

// Config for table and form
const tableConfig = [
  {
    id: "payoutId",
    visible: true,
    header: "Payout id",
    cell: ({ row: { original }, getValue }) => {
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/payouts/${original._id}`}
        >
          {getValue()}
        </Link>
      );
    },
  },
  {
    id: "status",
    editable: {
      type: "select",
      options: statusOptions,
    },
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "payDate",
    cell: ({ getValue }) => {
      return formatDate(getValue(), null, true);
    },
  },
  {
    id: "employee",
    header: "Employee",
    cell: ({ getValue, row: { original } }) => {
      const value = getValue();
      if (!value) return;
      return value.user?.fullName;
    },
    //   return value.length > 1
    //     ? original.organization?.name
    //     : value[0]?.fullName || value[0]?.firstName;
    // },
  },
  {
    id: "total",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    id: "dateRange",
    header: "Date Range",
    cell: ({ row: { original } }) => {
      const startDate = formatDate(original.startDate, null, true);
      const endDate = formatDate(original.endDate, null, true);
      return (
        <div className="whitespace-nowrap">
          {startDate} - {endDate}
        </div>
      );
    },
  },

  // {
  //   id: "active",
  //   cell: ({ row: { original }, value }) => {
  //     return value ? (
  //       <CheckCircleIcon className="w-5 h-5 text-green-500" />
  //     ) : (
  //       <XCircleIcon className="w-5 h-5 text-red-500" />
  //     );
  //   },
  // },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Jobs", value: 10 },
  { label: "20 Jobs", value: 20 },
  { label: "50 Jobs", value: 50 },
  { label: "100 Jobs", value: 100 },
  { label: "150 Jobs", value: 150 },
];
