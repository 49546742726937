import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../utils";
import Button from "../Button";
import Modal from "../Modal";
import Radio from "../Radio";
import Toggle from "../Toggle";

const TableSettings = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [visibleCols, setVisibleCols] = useState({});
  const [pageSize, setPageSize] = useState(
    () =>
      props.pageSize ||
      (props.pageSizeOptions && props.pageSizeOptions[0].value) ||
      20
  );

  // useEffect(() => {
  //   props.table.setPageSize(props.pageSize || 20);
  // }, []);

  function handlePageSizeChange(e) {
    setPageSize(Number(e.value));
  }

  function handleSave() {
    pageSize && props.table.setPageSize(Number(pageSize));
    props.table.setColumnVisibility((e) => {
      return { ...e, ...visibleCols };
    });
    setModalVisible(false);
  }

  function handleClose() {
    setVisibleCols({});
    setPageSize(props.pageSize);
    setModalVisible(false);
  }

  function handleVisibilityChange({ target }) {
    setVisibleCols({ ...visibleCols, [target.id]: target.checked });
  }

  const pageSizeChanged = pageSize !== props.pageSize;

  const modalFooter = (
    <>
      <Button onClick={handleClose} type="alternative">
        Close
      </Button>
      <Button onClick={handleSave} type="primary">
        Save
      </Button>
    </>
  );

  const modalContent = (
    <div className="grid grid-cols-2 divide-x">
      {props.pageSizeOptions && (
        <div className="p-2 md:px-10">
          <h3 className="text-lg lg:text-2xl font-bold">Page Count</h3>
          <div>
            {props.pageSizeOptions.map((item, i) => {
              let active = item.value === Number(pageSize);
              return (
                <Radio
                  size="sm"
                  onChange={handlePageSizeChange}
                  name="page-size"
                  checked={active}
                  {...item}
                  key={i}
                  id={i}
                />
              );
            })}
          </div>
        </div>
      )}
      <div className="pb-2 md:px-10">
        <h3 className="text-lg lg:text-2xl font-bold pb-3">Visible Columns</h3>
        <div>
          {props.table
            ?.getAllColumns()
            .filter((item) => item.id !== "select")
            .map((column) => {
              return (
                <div key={column.id}>
                  <Toggle
                    size="xs"
                    label={capitalizeFirstLetter(
                      column.Header || column.header || column.id
                    )}
                    setToggled={handleVisibilityChange}
                    id={column.id}
                    toggled={column.getIsVisible()}
                    // {...column.getToggleHiddenProps()}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Cog8ToothIcon
        onClick={() => setModalVisible(true)}
        className="stroke-2 stroke-primary-800 h-6 w-6 cursor-pointer lg:ml-2"
      />
      <Modal
        visible={modalVisible}
        setVisible={setModalVisible}
        onClose={() => setModalVisible(false)}
        title="Table Preferences"
        footer={modalFooter}
        content={modalContent}
        size="lg"
        footerPosition="right"
      />
    </>
  );
};

export default TableSettings;
