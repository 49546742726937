import React, { useState, useEffect } from "react";
import { Input, Select, Toggle } from "../../../shared";
// import OrgCreateForm from "../../Organizations/OrgCreateModal/OrgCreateForm";

const EmployeeCreateForm = ({
  form = {},
  updateForm,
  errors = {},
  employee,
  employeeOrg,
  setEmployeeOrg,
  clearForm,
  hideOrg,
  newEmployee,
  orgOptions,
  user,
}) => {
  // Add state for account creation toggle
  const [createAccount, setCreateAccount] = useState(false);

  // Update form whenever createAccount changes
  useEffect(() => {
    updateForm({ id: "createAccount", value: createAccount });
  }, [createAccount]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2 mb-4">
        {newEmployee && (
          <div className="flex items-center gap-3">
            <h3 className="text-lg font-medium text-gray-900">
              Create User Account
            </h3>
            <Toggle
              size="sm"
              checked={createAccount}
              onChange={() => setCreateAccount(!createAccount)}
            />
          </div>
        )}
      </div>
      <div>
        <Input
          id="fullName"
          label="Full Name"
          placeholder="Full Name"
          onChange={updateForm}
          value={form.fullName}
          error={errors.fullName}
        />
      </div>
      <div>
        <Input
          id="email"
          label="Email"
          placeholder="Email"
          onChange={updateForm}
          value={form.email}
          error={errors.email}
        />
      </div>
      <div>
        <Input
          id="phone"
          label="Phone"
          placeholder="Phone"
          onChange={updateForm}
          value={form.phone}
          error={errors.phone}
        />
      </div>
      <div>
        <Select
          id="role"
          label="Role"
          placeholder="Select Role"
          onChange={updateForm}
          value={form.role}
          error={errors.role}
          options={[
            { value: "Admin", label: "Admin" },
            { value: "Manager", label: "Manager" },
            { value: "Technician", label: "Technician" },
            { value: "Employee", label: "Employee" },
          ]}
        />
      </div>
      <div>
        <Input
          id="department"
          label="Department"
          placeholder="Department"
          onChange={updateForm}
          value={form.department}
        />
      </div>
    </div>
  );
};

export default EmployeeCreateForm;
