import { CheckboxList } from "./CheckboxList";
import { getStage, getStages } from "../pages/Jobs/job-stages";

export const StatusFilterList = (props) => {
  const stages = getStages();

  const statusList = Object.values(stages).map((stage) => {
    return { id: stage.id, name: stage.title };
  });

  function handleChange(val) {
    if (!val || val.length === 0) {
      props.onChange({
        title: props.value?.defaultText || "",
        value: "",
      });
      return;
    }

    const value = val.map(({ name }) => name).join(",");
    const title = value || props.value?.defaultText;

    if (value) {
      props.onChange({ title, value });
    }
  }

  return (
    <CheckboxList
      onChange={handleChange}
      objectKey="name"
      value={props.value.value}
      data={statusList}
    />
  );
};
