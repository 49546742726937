import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import Badge from "../../../shared/Badge";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import {
  useDeleteEquipmentMutation,
  useGetEquipmentByIdQuery,
  useUpdateEquipmentMutation,
} from "../../../redux/services/equipment";
import { DeleteEquipmentModal } from "../DeleteModal";
import EquipmentOverview from "./Overview";
import EquipmentMaintenance from "./Maintenance";
import EquipmentQr from "./EquipmentQr";
import COLUMN_DEFINITIONS from "./tableconfig";
export const EquipmentShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { form, updateForm } = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [headerContext, setHeaderContext] = useState(null);

  const { data: equipment, isLoading } = useGetEquipmentByIdQuery({ id });
  const [updateEquipment] = useUpdateEquipmentMutation();
  const [deleteEquipment] = useDeleteEquipmentMutation();

  const headerRef = useRef(null);

  const handleSave = async (data) => {
    try {
      await updateEquipment({ id, ...data }).unwrap();
      setIsEditing(false);
      toast.success("Equipment updated successfully");
    } catch (error) {
      toast.error("Failed to update equipment");
    }
  };

  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, []);

  const renderTabHeader = useCallback(
    (headerProps) => {
      return (
        <div className="flex flex-row justify-between items-center">
          <div>{headerProps}</div>
        </div>
      );
    },
    [equipment]
  );

  const maintenanceHistory = equipment?.maintenanceHistory || [];

  const tabs = useMemo(
    () => [
      {
        id: "Overview",
        content: (
          <EquipmentOverview
            updateForm={updateForm}
            onUpdate={handleSave}
            equipment={equipment}
            maintenanceHistory={maintenanceHistory}
            tableConfig={COLUMN_DEFINITIONS}
          />
        ),
      },
      {
        id: "Maintenance",
        content: (
          <EquipmentMaintenance
            equipment={equipment}
            maintenanceHistory={maintenanceHistory}
            tableConfig={COLUMN_DEFINITIONS}
          />
        ),
      },
      {
        id: "QR Code",
        content: <EquipmentQr equipment={equipment} />,
      },
    ],
    [equipment]
  );

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = async () => {
    try {
      await deleteEquipment(id).unwrap();
      navigate("/equipment");
      toast.success("Equipment deleted successfully");
    } catch (error) {
      toast.error("Failed to delete equipment");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 hidden"></div>
            {headerProps.actions}
            <div
              onClick={() => headerProps.setOpen(true)}
              className="hover:bg-gray-100 cursor-pointer p-2 rounded-lg block lg:hidden"
            >
              <ChevronDoubleLeftIcon className="w-5 text-gray-800" />
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b"></div>
      </div>
    );
  };

  const title = (
    <div className="flex items-center space-x-2">
      <div className="text-lg font-semibold text-gray-800">
        {equipment?.name}
      </div>
      <div>
        <Badge color={"primary"}>{equipment?.status}</Badge>
      </div>
    </div>
  );

  return (
    <>
      <SidebarContainer title={title} header={renderHeader} hideSidebar={true}>
        {({ parentHeight, parentWidth }) => {
          const offsetTop = headerContext?.offsetTop;
          const offsetHeight = headerContext?.offsetHeight;
          const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
          const panelStyle = {
            height: `${parentHeight - offsetTop - offsetHeight - 50}px`,
          };
          return (
            <Tabs
              className={`w-full overflow-hidden`}
              style={wrapperStyle}
              panelStyle={panelStyle}
              useSearchParams
              panelClasses={`p-2 border-t overflow-y-scroll`}
              tabListClasses="bg-white px-2 h-[38px]"
              tabclassName="mx-1"
              tabHeader={renderTabHeader}
              id="equipment-show"
              data={tabs}
            />
          );
        }}
      </SidebarContainer>
      <DeleteEquipmentModal
        visible={deleteModalOpen}
        setVisible={setDeleteModalOpen}
        equipment={equipment ? [equipment] : []}
        onDelete={handleDelete}
      />
    </>
  );
};
