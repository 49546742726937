import { api } from "../../api";

const templatesApi = api.injectEndpoints({
  tagTypes: ["Templates"],
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = new URLSearchParams({
          page,
          limit,
          ...rest,
        });
        return `templates?${query}`;
      },
      transformResponse: (response) => response.data,
      providesTags: (data) =>
        data?.templates
          ? [
              ...data.templates.map(({ id }) => ({ type: "Templates", id })),
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
    addTemplate: builder.mutation({
      query(body) {
        return {
          url: "templates",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Templates", id: "LIST" }],
    }),
    getTemplate: builder.query({
      query: (id) => `templates/${id}`,
      transformResponse: (response) => response.template,
      providesTags: (_result, _error, id) => [{ type: "Templates", id }],
    }),
    updateTemplate: builder.mutation({
      query({ id, data }) {
        return {
          url: `/templates/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Templates", id }],
    }),
    deleteTemplate: builder.mutation({
      query(id) {
        return {
          url: `/templates/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, id) => [
        { type: "Templates", id: "LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTemplatesQuery,
  useGetTemplateQuery,
  useUpdateTemplateMutation,
  useAddTemplateMutation,
  useDeleteTemplateMutation,
} = templatesApi;
