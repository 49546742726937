import { COLUMN_DEFINITIONS as serviceColumnDefs } from "./service.pdf-table.config";
// import { TOTAL_COLUMN_DEFINITIONS as serviceTotalsColumnDefs } from "./service.pdf.totals.config";
import { COLUMN_DEFINITIONS as truckingColumnDefs } from "./trucking.pdf-table.config";
// import { TOTAL_COLUMN_DEFINITIONS as truckingTotalsColumnDefs } from "./trucking.pdf.totals.config";

// Add default configuration
const defaultConfig = {
  COLUMN_DEFINITIONS: [],
  //   TOTAL_COLUMN_DEFINITIONS: [],
};

export const getPdfConfig = (appType) => {
  if (!appType) return { pdfConfig: defaultConfig };

  try {
    const configs = {
      servicerepair: {
        COLUMN_DEFINITIONS: serviceColumnDefs || [],
        // TOTAL_COLUMN_DEFINITIONS: serviceTotalsColumnDefs || [],
      },
      trucking: {
        COLUMN_DEFINITIONS: truckingColumnDefs || [],
        // TOTAL_COLUMN_DEFINITIONS: truckingTotalsColumnDefs || [],
      },
    };

    return {
      pdfConfig: configs[appType.toLowerCase()] || defaultConfig,
    };
  } catch (error) {
    console.error("Error in getPdfConfig:", error);
    return { pdfConfig: defaultConfig };
  }
};

// Make sure we're exporting correctly
export default getPdfConfig;
