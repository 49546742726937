// AddPartForm.jsx
import { useMemo, useState } from "react";
import { Select, Input, Button } from "../../shared";
/**
 * AddPartForm component handles both selecting existing parts and creating new ones
 * @param {Object} props - Component props
 * @param {Object} props.form - Form state object
 * @param {Function} props.handleInputChange - Function to handle input changes
 * @param {Function} props.handlePartCreated - Function to handle when a new part is created
 * @param {Array} props.partOptions - Array of available part options
 * @param {Object} props.errors - Form validation errors
 * @param {Function} props.globalFilter - Function to handle search filtering
 * @param {Function} props.updateForm - Function to update form state
 * @param {Function} props.handleSubmit - Function to handle form submission
 * @param {Function} props.handleAddPart - Function to handle adding selected part
 */
export const AddPartForm = ({
  form,
  handleInputChange,
  handlePartCreated,
  partOptions,
  errors,
  globalFilter,
  updateForm,
  handleSubmit,
  handleAddPart,
  handleSelectChange,
  searchValue,
  categories,
}) => {
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  const renderPartValue = () => {
    if (!form.part) return "";

    const { name = "", partNumber = "", description = "" } = form.part;
    return `${name || ""} • ${partNumber || ""} • ${description || ""}`;
  };

  // Handle switching to new part creation mode
  const handleCreateNew = (searchValue) => {
    setIsCreatingNew(true);
    // Pre-fill the name field with the search value
    handleInputChange({ id: "name", value: searchValue });
  };

  // Reset creation mode when selecting an existing part
  const handlePartSelect = (value) => {
    setIsCreatingNew(false);
    handleSelectChange(value);
  };

  const renderEmptyLabel = (searchValue) => {
    return (
      <div
        className="cursor-pointer p-2 hover:bg-gray-100 w-full"
        onClick={() => handleCreateNew(searchValue)}
      >
        Create new part: {searchValue}
      </div>
    );
  };

  const parts = useMemo(() => {
    if (partOptions.length === 0) {
      return [
        {
          fullValue: "EMPTY_VALUE",
          value: "EMPTY_VALUE",
          label: renderEmptyLabel(searchValue),
        },
      ];
    }

    return partOptions;
  }, [partOptions, searchValue]);

  return (
    <div className="space-y-4">
      {!isCreatingNew ? (
        <div className="space-y-4">
          <Select
            id="inventoryItem"
            search
            searchKeys={["name", "partNumber", "description"]}
            value={renderPartValue()}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, "part")
            }
            label="Select Part"
            options={parts}
            emptyLabel={renderEmptyLabel(searchValue)}
            placeholder="Search Inventory Items"
            error={errors.part}
            fullTextSearch={globalFilter}
          />
          {form.part && (
            <>
              <Input
                label="Quantity"
                name="quantity"
                type="number"
                value={form.quantity}
                onChange={handleInputChange}
                placeholder="Quantity"
              />

              <Input
                label="Unit Price"
                name="unitPrice"
                type="number"
                value={form.unitPrice}
                onChange={handleInputChange}
                placeholder="Unit Price"
              />

              <div className="flex justify-end">
                <Button
                  onClick={handleAddPart}
                  type="primary"
                  className="text-sm"
                >
                  Confirm Add Part
                </Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Create New Part</h3>
            <Button
              type="text"
              onClick={() => setIsCreatingNew(false)}
              className="text-sm"
            >
              Back to Search
            </Button>
          </div>

          <Select
            label="Category"
            name="category"
            value={form.category}
            onChange={(selectedOption) =>
              handleSelectChange({ name: "category", value: selectedOption })
            }
            options={categories.map((category) => ({
              value: category.label,
              label: category.label,
            }))}
            placeholder="Select a category"
          />

          <Input
            label="Item Name"
            name="name"
            value={form.name}
            onChange={handleInputChange}
            placeholder="Name"
          />

          <Input
            label="Part Number"
            name="partNumber"
            value={form.partNumber}
            onChange={handleInputChange}
            placeholder="Part Number"
          />

          <Input
            label="Description"
            name="description"
            value={form.description}
            onChange={handleInputChange}
            placeholder="Description"
          />

          <Input
            label="Quantity"
            name="quantity"
            type="number"
            value={form.quantity}
            onChange={handleInputChange}
            placeholder="Quantity"
          />

          <Input
            label="Unit Price"
            name="unitPrice"
            type="number"
            value={form.unitPrice}
            onChange={handleInputChange}
            placeholder="Unit Price"
          />

          <Input
            id="supplier"
            label="Supplier"
            value={form.supplier}
            onChange={updateForm}
          />

          <div className="flex justify-end">
            <Button
              onClick={() => {
                handleAddPart();
                setIsCreatingNew(false);
              }}
              type="primary"
              className="text-sm"
            >
              Create and Add Part
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPartForm;
