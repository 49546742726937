import React from "react";
import { Button, Modal } from "../../shared";
import { ClientsDropdown } from "../ClientsDropdown";
import useForm from "../../hooks/useForm";

/**
 * Modal component for selecting a client
 * @param {Object} props - Component props
 * @param {boolean} props.modalOpen - Controls modal visibility
 * @param {Function} props.setModalOpen - Function to set modal visibility
 * @param {Function} props.onSelect - Callback function when client is selected
 * @param {string} props.excludeClientId - Optional client ID to exclude from selection
 * @returns {JSX.Element} Rendered component
 */
const ClientSelectModal = ({
  modalOpen,
  setModalOpen,
  onSave,
  selectedItems,
  onSelect,
  excludeClientId,
}) => {
  const { form, updateForm, clearForm } = useForm();

  const handleClose = () => {
    setModalOpen(false);
    clearForm();
  };

  const handleSubmit = () => {
    if (form.client) {
      onSave(form.client._id);
      handleClose();
    }
    if (selectedItems) {
      onSave(selectedItems, form.client._id);
      handleClose();
    }
  };

  const footer = (
    <div>
      <Button className="mr-3" onClick={handleClose} type="alternative">
        Cancel
      </Button>
      <Button onClick={handleSubmit} type="primary" disabled={!form.client}>
        Submit
      </Button>
    </div>
  );

  const content = (
    <div className="space-y-4">
      <ClientsDropdown
        form={form}
        updateForm={updateForm}
        onClick={(client) => updateForm({ id: "client", value: client })}
        value={form.client}
        excludeClientId={excludeClientId}
      />
    </div>
  );

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={handleClose}
      title="Select Client"
      footer={footer}
      content={content}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

export default ClientSelectModal;
