import { Select } from "../shared";
import { SERVICE_TYPE_OPTIONS } from "../constants/select";

const ServiceTypeSelect = ({ value, onChange, errors, hideLabel, size }) => {
  return (
    <Select
      wrapperClasses="col-span-2"
      id="serviceType"
      value={value}
      onChange={onChange}
      label={hideLabel ? "" : "Service Type"}
      options={SERVICE_TYPE_OPTIONS}
      placeholder="Select Service Type"
      error={errors}
      size={size}
    />
  );
};

export default ServiceTypeSelect;
