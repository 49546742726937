import { PlusIcon } from "@heroicons/react/24/outline";
import {
  Avatar,
  Button,
  Card,
  Table,
  EditableText,
  Input,
} from "../../../shared";
import { AvatarClient, ClientsDropdown } from "../../../components";
import TruckMaintenanceDisplay from "../TruckMaintenanceDisplay";
import { formatDate } from "../../../utils";
import { useState } from "react";

const EquipmentOverview = ({
  equipment,
  maintenanceHistory,
  tableConfig,
  onUpdate,
  updateForm,
}) => {
  const [isEditingClient, setIsEditingClient] = useState(false);

  const handleSave = async (data) => {
    updateForm(data);
    if (onUpdate) {
      await onUpdate(data);
    }
  };

  const handleClientChange = async (selectedClient) => {
    const data = { client: selectedClient._id };
    await handleSave(data);
    setIsEditingClient(false);
  };

  const handleClearClient = async () => {
    const data = { clientId: null };
    await handleSave(data);
    setIsEditingClient(false);
    setSearchTerm("");
  };

  const EquipmentInfoEditable = ({ id, value, icon, type, displayText }) => {
    return (
      <div className="flex items-center">
        {icon && <span className="text-gray-500 mr-2">{icon}</span>}
        <EditableText
          displayText={(e) => (
            <span className="text-lg font-semibold text-gray-800">
              {displayText ? displayText(e) : e || "N/A"}
            </span>
          )}
          id={id}
          iconClasses="h-3 w-3"
          value={value}
          onSave={(e) => handleSave(e.data)}
          type={type}
        />
      </div>
    );
  };

  const renderClientSection = () => {
    if (!isEditingClient) {
      return equipment.client ? (
        <div className="flex items-center space-x-4 mb-6">
          <Avatar src={equipment.client.avatar} size="large" />
          <div>
            <h3 className="text-lg font-semibold">{equipment.client.name}</h3>
          </div>
        </div>
      ) : (
        <p>No client associated with this equipment</p>
      );
    }

    const clientForm = {
      client: equipment.client || null,
    };

    return (
      <ClientsDropdown
        form={clientForm}
        updateForm={(data) => {
          if (data.value) {
            handleClientChange(data.value);
          }
        }}
        value={equipment.client}
        onClick={handleClientChange}
      />
    );
  };

  return (
    <div className="space-y-4">
      <Card className="w-full bg-white rounded-lg shadow-sm p-3 mb-4">
        <div className="flex justify-between items-center mb-4 border-b pb-2">
          <h2 className="text-xl font-semibold text-gray-800">
            Equipment Details
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-tag"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Name</h3>
              <EquipmentInfoEditable id="name" value={equipment.name} />
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-barcode"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Serial Number
              </h3>
              <EquipmentInfoEditable
                id="serialNumber"
                value={equipment.serialNumber}
              />
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-cogs"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Model</h3>
              <EquipmentInfoEditable id="model" value={equipment.model} />
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-industry"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Manufacturer
              </h3>
              <EquipmentInfoEditable
                id="manufacturer"
                value={equipment.manufacturer}
              />
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-wrench"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Type</h3>
              <p className="text-lg font-semibold text-gray-800">
                {equipment.type}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-calendar-alt"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Purchase Date
              </h3>
              <p className="text-lg font-semibold text-gray-800">
                {equipment.purchaseDate
                  ? new Date(equipment.purchaseDate).toLocaleDateString()
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-calendar-check"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Last Maintenance Date
              </h3>
              <p className="text-lg font-semibold text-gray-800">
                {equipment.lastMaintenanceDate
                  ? new Date(equipment.lastMaintenanceDate).toLocaleDateString()
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-gray-500">
              <i className="fas fa-calendar-plus"></i>
            </span>
            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Next Maintenance Date
              </h3>
              <p className="text-lg font-semibold text-gray-800">
                {equipment.nextMaintenanceDate
                  ? new Date(equipment.nextMaintenanceDate).toLocaleDateString()
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </Card>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Maintenance History</h2>
            <Button
              onClick={() => null}
              className="flex flex-row items-center space-x-2"
              size="sm"
            >
              <PlusIcon className="w-4 h-4" />
              Add Record
            </Button>
          </div>
          <Table data={maintenanceHistory} columns={tableConfig} />
        </div>
        <Card className="bg-white rounded-lg shadow-sm p-6 col-span-2">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Associated Client</h2>
            <Button
              variant="text"
              size="sm"
              onClick={() => setIsEditingClient(!isEditingClient)}
            >
              {isEditingClient ? "Cancel" : "Change"}
            </Button>
          </div>
          {renderClientSection()}
        </Card>
      </div>
    </div>
  );
};

export default EquipmentOverview;
