import { Select, Input, Modal, Button } from "../../shared";
import { accountTypes } from "../../constants/select";
import { useGetClientsQuery } from "../../redux/services/clients/clientsApi";
import { useMemo } from "react";
import { useConfig } from "../../hooks";

const UserAddModal = ({ form, visible, setVisible, submit, loading }) => {
  const isClientUser = form.form.role === "Client";
  const { appType } = useConfig();
  const { data: clients } = useGetClientsQuery({
    page: 1,
    limit: 100,
  });

  function handleCancelModal() {
    setVisible(false);
    form.clearForm();
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={submit}
        type="primary"
      >
        Submit
      </Button>
    </>
  );

  const clientOptions = useMemo(
    () =>
      clients &&
      clients.clients.map((i) => ({
        label: i.name,
        value: { _id: i._id, name: i.name },
      })),
    [clients?.clients.length]
  );

  const modalContent = (
    <div className="grid gap-3 sm:grid-cols-3">
      <Input
        id="email"
        label="Email"
        value={form.form.email}
        onChange={form.updateForm}
        placeholder="user@example.com"
        wrapperClasses="col-span-2"
      />
      <Select
        id="role"
        onChange={form.updateForm}
        label="Role"
        value={form.form.role}
        placeholder="Select..."
        options={accountTypes.filter((i) => {
          return i.appType.includes(appType);
        })}
      />
      {form.form?.role === "Client" && (
        <Select
          options={clientOptions}
          onChange={(e) =>
            form.updateForm({
              id: e.id,
              value: { _id: e.value._id, name: e.value.name },
            })
          }
          value={form.form.client?.name}
          id="client"
          label="Client"
          wrapperClasses="col-span-2"
          placeholder="Select a client..."
        />
      )}
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
      title="Invite User"
      footer={modalFooter}
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};

export default UserAddModal;
