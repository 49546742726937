import { Link } from "react-router-dom";
import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import {
  formatDate,
  renderAddress,
  renderAmount,
  formatDateTime,
  formatTimeWorked,
  capitalizeFirstLetter,
  formatDateTimeRange,
} from "../../../../../utils";
import { payoutRateOptions } from "../../../../../constants/select";
import { Badge } from "../../../../../shared";

// Config for table and forms
const tableConfig = [
  {
    Header: "#",
    accessor: "",
    Cell: (row) => {
      return row.row.id * 1 + 1;
    },
    disableSortBy: true,
    disableFilters: true,
    width: 10,
  },
  {
    id: "shiftInfo",
    header: "Shift Info",
    cell: ({ row }) => {
      const { startTime, endTime } = row.original;
      const timeRange = formatDateTimeRange(startTime, endTime, {
        showSeconds: false,
        format24Hour: false,
        shortDate: true,
        separator: "-",
      });

      return (
        <div className="flex items-center space-x-1">
          <span className="text-gray-600">{timeRange}</span>
        </div>
      );
    },
  },
  // {
  //   id: "status",
  //   header: "Status",
  //   minWidth: 140,
  //   cell: ({ getValue }) => (
  //     <Badge
  //       label={capitalizeFirstLetter(getValue())}
  //       // variant={getStatusVariant(getValue())}
  //     />
  //   ),
  // },
  {
    id: "totalWorkTime",
    header: "Time Worked",
    minWidth: 100,
    cell: ({ getValue }) => `${formatTimeWorked(getValue()).display}`,
  },
  {
    id: "overtime",
    header: "Overtime",
    minWidth: 100,
    cell: ({ getValue }) => {
      const overtime = getValue();
      if (overtime === 0) return "-";
      return `${formatTimeWorked(overtime).display}`;
    },
  },
  // {
  //   id: "breaks",
  //   header: "Breaks",
  //   minWidth: 160,
  //   cell: ({ getValue }) => {
  //     const breaks = getValue();
  //     if (!breaks || breaks.length === 0) return "-";
  //     return breaks.map((breakItem, index) => (
  //       <Badge
  //         key={index}
  //         label={`${breakItem.type} ${breakItem.active ? "(Active)" : ""}`}
  //         className="mr-1 mb-1"
  //       />
  //     ));
  //   },
  // },
  {
    id: "totalBreakTime",
    header: "Break Time",
    minWidth: 100,
    cell: ({ getValue }) => `${formatTimeWorked(getValue()).display}`,
  },
  // {
  //   id: "location",
  //   header: "Location",
  //   minWidth: 200,
  //   cell: ({ getValue }) => {
  //     const location = getValue();
  //     if (!location?.coordinates) return "-";
  //     return `${location.coordinates[1]}, ${location.coordinates[0]}`;
  //   },
  // },
  // {
  //   id: "notes",
  //   header: "Notes",
  //   minWidth: 200,
  //   cell: ({ getValue }) => getValue() || "-",
  // },
  {
    Header: "Pay Type",
    id: "payoutType",
    width: 90,
    editable: {
      type: "select",
      options: payoutRateOptions,
    },
    cell: ({ getValue }) => getValue(),
  },
  {
    header: "Payout Rate",
    id: "payoutRate",
    size: 150,
    editable: {
      type: "number",
    },
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    header: "Hourly Total",
    id: "payoutHourlyTotal",
    width: 90,
    // editable: {
    //   type: "number",
    // },
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    header: "Supplemental",
    id: "payoutSupplemental",
    width: 90,
    editable: {
      type: "number",
    },
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    header: "Total",
    width: 90,
    id: "payoutTotal",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
