import Card from "../../../shared/Card";
import { renderAmount, renderTotalMileage } from "../../../utils";
import { isAuthorized } from "../../../utils/authority";

// Common keys for both types
const commonKeys = [
  { title: "Other Charges", key: "totalOtherCharges", type: "amount" },
];

// Trucking specific keys
const truckingKeys = [
  { title: "Total Mileage", key: "totalMileage", type: "mi" },
  { title: "Total Weight", key: "totalWeight", type: "lbs" },
  { title: "Base Charges", key: "totalBaseCharges", type: "amount" },
  { title: "Drop Charges", key: "totalDropCharges", type: "amount" },
  { title: "Fuel Charges", key: "totalFuelCharges", type: "amount" },
  { title: "Total Standby Time", key: "totalStandByTime", type: "hrs" },
];

// Service repair specific keys
const serviceRepairKeys = [
  { title: "Parts Total", key: "partsTotal", type: "amount" },
  { title: "Labor Total", key: "hourlyTotal", type: "amount" },
  { title: "Service Fee Total", key: "serviceFeeTotal", type: "amount" },
  { title: "Sales Tax", key: "salesTax", type: "amount" },
];

const formatValue = (type, value, info, appType) => {
  if (value === "calculatedTotalCharges") {
    return renderAmount(info[value]);
  }
  switch (type) {
    case "amount":
      return renderAmount(
        appType === "serviceRepair" ? info.totals?.[value] : info[value]
      );
    case "mi":
      return `${renderTotalMileage(info.totalMileage)} mi`;
    case "hrs":
      return `${info.totalStandByTime || 0} hrs`;
    case "lbs":
      return `${info.totalWeight || 0} lbs`;
    default:
      return info[value] || 0;
  }
};

const InvoiceTotals = ({ info, appType, ...props }) => {
  // Determine which keys to use based on appType
  const keys = [
    ...commonKeys,
    ...(appType === "trucking" ? truckingKeys : serviceRepairKeys),
  ];

  return (
    <>
      <Card className="row-span-2 px-10 py-4">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:grid-cols-4 xl:grid-cols-9">
          <div className="flex flex-col">
            <span className="text-gray-500 mb-1 font-semibold">
              Invoice Total
            </span>
            <span className="font-semibold text-lg">
              <p>
                {formatValue(
                  "amount",
                  appType === "serviceRepair"
                    ? "calculatedTotalCharges"
                    : "total",
                  info,
                  appType
                )}
              </p>
            </span>
          </div>
          {keys.map((item) => (
            <div className="flex flex-col">
              <span className="text-gray-500 mb-1 font-semibold">
                {item.title}
              </span>
              <span className="font-semibold text-lg">
                <p>{formatValue(item.type, item.key, info, appType)}</p>
              </span>
            </div>
          ))}
        </div>
      </Card>

      {/* <Modal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title="Table Preferences"
        footer={modalFooter}
        content={modalContent}
        size="md"
        footerPosition="right"
      /> */}
    </>
  );
};

export default InvoiceTotals;
