import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TableLayout from "../../../layouts/TableLayout/NewTableLayout";
import { useAuth } from "../../../hooks/useAuth";
import { useDebounceSearch } from "../../../hooks";
import { isAuthorized } from "../../../utils/authority";
import { toast } from "react-toastify";
import EquipmentCreateModal from "../../../pages/Equipment/CreateModal";
import ClientSelectModal from "../../../components/Clients/ClientSelectModal";
import {
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
} from "../../../redux/services/equipment";
import { DeleteEquipmentModal } from "../../Equipment/DeleteModal";

/**
/**
 * ClientEquipmentIndex component displays a table of equipment for a specific client
 * @param {Object} props - Component props
 * @param {Array} props.equipment - Array of equipment items
 * @param {Object} props.client - Client object containing client information
 * @param {boolean} props.loading - Loading state
 * @param {Function} props.onUpdate - Function to handle equipment updates
 * @returns {JSX.Element} Rendered component
 */
const ClientEquipmentIndex = ({
  equipment = [],
  client,
  loading,
  onUpdate,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  // Search and pagination state
  const { search, handleSearch } = useDebounceSearch();
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [deselectItems, setDeselectItems] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  // Modal state
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteEquipment] = useDeleteEquipmentMutation();

  // Add new state for client selection modal
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateEquipment] = useUpdateEquipmentMutation();

  // Handle create modal success
  const handleCreateSuccess = (newEquipment) => {
    if (onUpdate) {
      onUpdate();
    }
    setCreateModalOpen(false);
  };

  // Table column definitions
  const COLUMN_DEFINITIONS = [
    {
      id: "name",
      header: "Name",
      accessorKey: "name",
      cell: ({ row }) => (
        <span
          className="text-[#3DA58A] cursor-pointer hover:underline"
          onClick={() => navigate(`/equipment/${row.original._id}`)}
        >
          {row.original.name}
        </span>
      ),
    },
    {
      id: "manufacturer",
      header: "Manufacturer",
      accessorKey: "manufacturer",
    },
    {
      id: "model",
      header: "Model",
      accessorKey: "model",
    },
    {
      id: "serialNumber",
      header: "Serial Number",
      accessorKey: "serialNumber",
    },
    {
      id: "status",
      header: "Status",
      accessorKey: "status",
    },
  ];

  /**
   * Handles editing an equipment item
   * @param {string} itemId - ID of the equipment to edit
   */
  function handleEdit(itemId) {
    navigate(`/equipment/${itemId}`);
  }

  /**
   * Handles updating equipment data
   * @param {Object} e - Event object containing update data
   * @param {Function} setLoading - Function to set loading state
   */
  async function handleUpdate(e, setLoading) {
    if (onUpdate) {
      await onUpdate(e);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  function handleDeleteClick() {
    setDeleteVisible(true);
  }

  /**
   * Handles global filter changes
   * @param {string} value - Search value
   */
  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleSearch(value);
  }

  const cannotCreateOrEdit = !isAuthorized("owner,admin");

  // Calculate pagination data
  const paginationData = {
    totalDocs: equipment.length,
    limit: pageSize,
    totalPages: Math.ceil(equipment.length / pageSize),
    page: pageIndex + 1,
    pagingCounter: pageIndex * pageSize + 1,
    hasPrevPage: pageIndex > 0,
    hasNextPage: (pageIndex + 1) * pageSize < equipment.length,
    prevPage: pageIndex > 0 ? pageIndex : null,
    nextPage:
      (pageIndex + 1) * pageSize < equipment.length ? pageIndex + 2 : null,
  };

  // Ensure equipment is an array
  const tableData = useMemo(() => {
    if (loading) return [];
    if (!equipment) return [];
    // If equipment.data exists, use it, otherwise use equipment if it's an array
    return Array.isArray(equipment?.data)
      ? equipment.data
      : Array.isArray(equipment)
      ? equipment
      : [];
  }, [equipment, loading]);

  /**
   * Handles the transfer of equipment to a new client
   * @param {Object} newClient - The client to transfer equipment to
   */
  const handleTransferEquipment = async (selectedItems, newClientId) => {
    try {
      // Add your API call here to update equipment client IDs
      await updateEquipment({
        equipmentIds: selectedItems.map((row) => row._id),
        clientId: newClientId,
      });

      toast.success("Equipment transferred successfully");
      setDeselectItems(true);
      setTimeout(() => setDeselectItems(false), 500);
      setTransferModalOpen(false);
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      toast.error("Failed to transfer equipment");
    }
  };

  const handleMove = (selectedItems) => {
    setSelectedEquipment(selectedItems);
    setTransferModalOpen(true);
  };

  return (
    <div>
      <TableLayout
        id="equipment"
        title="Equipment"
        buttonSize="xs"
        loading={loading}
        columns={COLUMN_DEFINITIONS}
        data={tableData}
        pageSizeOptions={[10, 20, 30, 40, 50]}
        pagination={paginationData}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        handleEdit={handleEdit}
        onUpdate={handleUpdate}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
        globalSearchFilters={["name", "serialNumber"]}
        fullTextSearch={globalFilter}
        hideCheckbox={!isAuthorized("owner,admin")}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        clearSelectedItems={deselectItems}
        handleMove={handleMove}
        onSelectedRowsChange={setSelectedRows}
        handleDeleteClick={handleDeleteClick}
      />

      {/* Add Client Selection Modal */}
      <ClientSelectModal
        selectedItems={selectedEquipment}
        modalOpen={transferModalOpen}
        setModalOpen={setTransferModalOpen}
        onSave={handleTransferEquipment}
      />

      <EquipmentCreateModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
        clientId={client?._id}
        onSuccess={handleCreateSuccess}
        disableNavigation
      />
      <DeleteEquipmentModal
        visible={deleteVisible}
        setVisible={setDeleteVisible}
        equipment={selectedRows}
        deselectItems={() => {
          setDeselectItems(true);
          setTimeout(() => setDeselectItems(false), 500);
        }}
      />
    </div>
  );
};

export default ClientEquipmentIndex;
