import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useCrumbs } from "../../../hooks/useCrumbs";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";
import { Card, EditableText } from "../../../shared";
import {
  useGetEmployeeQuery,
  useUpdateEmployeeMutation,
  useCreateUserMutation,
} from "../../../redux/services/employees/employeesApi";
import EmployeeCard from "./EmployeeCard";
import EmployeeJobs from "./Jobs/EmployeeJobs";
import EmployeePayouts from "./Payouts/EmployeePayouts";
import EmployeeShifts from "./Shifts/EmployeeShifts";
import { Button } from "../../../shared/Button";
import { Modal } from "../../../shared/Modal";
import { toast } from "react-toastify";
import EmployeeCreateForm from "../../Employees/EmployeeCreateModal/EmployeeCreateForm";
// import JobChart from "./JobChart";

/**
 * EmployeeShow component displays detailed information about an employee
 * including their personal info, jobs, and payouts
 * @component
 */
const EmployeeShow = () => {
  const params = useParams();
  const { user } = useAuth();
  const crumbs = useCrumbs();

  // RTK Query hooks
  const { data: employee = {}, isLoading } = useGetEmployeeQuery({
    id: params.id,
  });
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [addUser] = useCreateUserMutation();

  const [reportData, setReportData] = useState([]);
  const [createUserModal, setCreateUserModal] = useState(false);

  // Initialize with empty values
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    phone: "",
    role: "",
    department: "",
    type: "employee",
    authority: "user",
    parentCompany: "",
    meta: {
      role: "",
      department: "",
    },
  });

  // Update userData when employee data is available
  useEffect(() => {
    if (employee && Object.keys(employee).length > 0) {
      setUserData({
        fullName: employee.user?.fullName || employee.fullName,
        email: employee.email,
        phone: employee.phone,
        role: employee.meta?.role || employee.role,
        department: employee.meta?.department || employee.department,
        type: "employee",
        authority: "user",
        parentCompany: employee.parentCompany,
        meta: {
          role: employee.meta?.role || employee.role,
          department: employee.meta?.department || employee.department,
        },
      });
    }
  }, [employee]);

  // Update breadcrumb when employee data loads
  useEffect(() => {
    if (employee._id) {
      crumbs.updateCrumbName({
        name: employee.fullName,
        path: "/employees/" + employee._id,
      });
      crumbs.setLoading(false);
    }
  }, [employee._id]);

  /**
   * Handles updating employee information
   * @param {Object} e - Update data object
   */
  async function handleUpdate(e) {
    const res = await updateEmployee({
      id: params.id,
      data: e,
    }).unwrap();
  }

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();

  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const handleCreateUser = async () => {
    try {
      // Create new user with employee data

      // Add any other required user fields

      const newUser = await addUser({
        id: employee._id,
        body: userData,
      }).unwrap();

      setCreateUserModal(false);
      toast.success("User account created successfully");
    } catch (error) {
      toast.error("Failed to create user account");
      console.error("Error creating user:", error);
    }
  };

  const renderHeader = (headerProps) => (
    <div
      ref={headerRef}
      className="p-2 border-b bg-white flex justify-between items-center"
    >
      {headerProps.title}
      {!employee.user?._id && (
        <Button
          variant="primary"
          size="sm"
          onClick={() => setCreateUserModal(true)}
        >
          Create User Account
        </Button>
      )}
    </div>
  );

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="h-auto">
          <div className="grid grid-cols-8 gap-3">
            <EmployeeCard
              info={employee}
              employeeStatus={isLoading ? "loading" : "success"}
              handleUpdate={handleUpdate}
            />
          </div>
        </div>
      ),
    },
    {
      id: "Jobs",
      content: (
        <div className="h-auto">
          <EmployeeJobs
            employee={employee}
            employeeStatus={isLoading ? "loading" : "success"}
          />
        </div>
      ),
    },
    {
      id: "Payouts",
      content: (
        <div className="h-auto">
          <EmployeePayouts
            employee={employee}
            employeeStatus={isLoading ? "loading" : "success"}
          />
        </div>
      ),
    },
    {
      id: "Shifts",
      content: (
        <div className="h-auto">
          <EmployeeShifts
            employee={employee}
            employeeStatus={isLoading ? "loading" : "success"}
          />
        </div>
      ),
    },
  ];

  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="fullName"
        value={employee.user?.fullName}
        size="sm"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        onSave={(e) => handleUpdate({ fullName: e.value })}
      />
    </div>
  );

  return (
    <>
      <SidebarContainer title={title} header={renderHeader} hideSidebar>
        {({ parentHeight, parentWidth }) => {
          const offsetTop = headerContext?.offsetTop;
          const offsetHeight = headerContext?.offsetHeight;
          const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
          const panelStyle = {
            height: `${parentHeight - offsetTop - offsetHeight - 70}px`,
          };

          return (
            <Tabs
              className="w-full overflow-hidden"
              style={wrapperStyle}
              panelStyle={panelStyle}
              useSearchParams
              panelClasses="p-2 border-t overflow-y-scroll"
              tabListClasses="bg-white px-2 h-[38px]"
              tabclassName="mx-1"
              id="employee-show"
              data={tabs}
            />
          );
        }}
      </SidebarContainer>
      <Modal
        title="Create User Account"
        visible={createUserModal}
        setVisible={setCreateUserModal}
        footer={
          <div className="flex justify-end space-x-2">
            <Button onClick={() => setCreateUserModal(false)} variant="outline">
              Cancel
            </Button>
            <Button onClick={handleCreateUser}>Create User</Button>
          </div>
        }
      >
        <EmployeeCreateForm
          form={userData}
          updateForm={(e) => {
            // Update the userData object when form changes
            setUserData((prev) => ({
              ...prev,
              [e.id]: e.value,
            }));
          }}
          hideOrg={true}
          user={user}
        />
      </Modal>
    </>
  );
};

export default EmployeeShow;
