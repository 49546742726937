import React, { useState } from "react";
import {
  Card,
  Button,
  DateRangePicker,
  Checkbox,
  Select,
  Badge,
} from "../../../../shared";
import {
  // useGetDriverReportsQuery,
  useGenerateDriverReportMutation,
  // useGetDriverReportQuery,
} from "../../../../redux/services/reports/reportsApi";

/**
 * DriverReports component displays and manages driver-specific reports
 * @param {Object} props - Component props
 * @param {Object} props.driver - Driver object containing driver information
 * @param {string} props.driverStatus - Current status of driver data loading
 */
const DriverReports = ({ driver, driverStatus }) => {
  const [reportType, setReportType] = useState();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [includePayouts, setIncludePayouts] = useState(true);
  const [selectedMetrics, setSelectedMetrics] = useState([
    "mileage",
    "earnings",
    "jobs",
  ]);

  const [generateReport, { data: reports, isLoading }] =
    useGenerateDriverReportMutation();

  const handleCreateReport = async () => {
    const reportConfig = {
      driverId: driver._id,
      reportType,
      year: selectedYear,
      months: selectedMonths,
      includePayouts,
      metrics: selectedMetrics,
    };

    try {
      await generateReport(reportConfig).unwrap();
    } catch (error) {
      console.error("Failed to create report:", error);
    }
  };

  const reportTypeOptions = [
    { value: "monthlyPayout", label: "Monthly Payout Report" },
    { value: "yearlyPayout", label: "Yearly Payout Report" },
    // { value: "monthlyPerformance", label: "Monthly Performance Report" },
    // { value: "yearlyPerformance", label: "Yearly Performance Report" },
    // { value: "legStatus", label: "Leg Status Analysis" },
    // { value: "payoutDetail", label: "Detailed Payout Report" },
    // { value: "performance", label: "Performance Report" },
  ];

  const yearOptions = Array.from({ length: 5 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year, label: year.toString() };
  });

  const monthOptions = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  const metricOptions = [
    { value: "mileage", label: "Total Mileage" },
    { value: "earnings", label: "Earnings" },
    { value: "jobs", label: "Job Completion" },
    { value: "waitTime", label: "Wait Time" },
    { value: "performance", label: "Performance Metrics" },
  ];

  const renderDateSelection = () => {
    if (reportType === "yearly") {
      return (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Year
          </label>
          <Select
            options={yearOptions}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          />
        </div>
      );
    }

    if (reportType === "monthly") {
      return (
        <div className="space-y-2">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Year
            </label>
            <Select
              options={yearOptions}
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            />
          </div>
          {reportType === "monthlyPayout" && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Months
              </label>
              <div className="grid grid-cols-2 gap-2">
                {monthOptions.map((month) => (
                  <Checkbox
                    key={month.value}
                    label={month.label}
                    checked={selectedMonths.includes(month.value)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedMonths(
                          [...selectedMonths, month.value].sort()
                        );
                      } else {
                        setSelectedMonths(
                          selectedMonths.filter((m) => m !== month.value)
                        );
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* Report Creation Card */}
      <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Create New Report</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Report Type
            </label>
            <Select
              options={reportTypeOptions}
              value={reportType}
              onChange={(e) => {
                setReportType(e.target);
                setSelectedMonths([]); // Reset months when changing report type
              }}
            />
          </div>

          {renderDateSelection()}

          {/* <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Include Metrics
            </label>
            <div className="space-y-2">
              {metricOptions.map((metric) => (
                <Checkbox
                  key={metric.value}
                  label={metric.label}
                  checked={selectedMetrics.includes(metric.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedMetrics([...selectedMetrics, metric.value]);
                    } else {
                      setSelectedMetrics(
                        selectedMetrics.filter((m) => m !== metric.value)
                      );
                    }
                  }}
                />
              ))}
            </div>
          </div> */}

          {/* <div>
            <Checkbox
              label="Include Payouts"
              checked={includePayouts}
              onChange={(e) => setIncludePayouts(e.target.checked)}
            />
          </div> */}

          <Button
            onClick={handleCreateReport}
            loading={isLoading}
            disabled={
              !selectedYear ||
              (reportType === "monthly" && selectedMonths.length === 0) ||
              selectedMetrics.length === 0
            }
          >
            Generate Report
          </Button>
        </div>
      </Card>

      {/* Reports List Card */}
      <Card className="p-4">
        <h3 className="text-lg font-semibold mb-4">Previous Reports</h3>
        {isLoading ? (
          <div>Loading reports...</div>
        ) : (
          <div className="space-y-2">
            {reports?.map((report) => (
              <div
                key={report._id}
                className="p-3 border rounded hover:bg-gray-50"
              >
                <div className="flex justify-between items-center">
                  <div className="space-y-1">
                    <div className="flex items-center space-x-2">
                      <span>
                        {new Date(report.createdAt).toLocaleDateString()}
                      </span>
                      <Badge label={report.reportType} />
                    </div>
                    <div className="text-sm text-gray-500">
                      Metrics: {report.metrics.join(", ")}
                    </div>
                  </div>
                  <Button size="sm" variant="outline">
                    Download
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};

export default DriverReports;
