import { useState } from "react";

const TruncatedText = ({ text, maxLength = 100, className, textClassName }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isLong = text?.length > maxLength;

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p
        className={
          className ||
          `text-gray-600 text-base whitespace-pre-wrap inline ${textClassName}`
        }
      >
        {isExpanded ? text : isLong ? text?.slice(0, maxLength) + "..." : text}
        {isLong && (
          <button
            onClick={handleClick}
            className="text-primary-600 hover:text-primary-800 text-sm font-medium ml-1"
          >
            {isExpanded ? "View less" : "View more"}
          </button>
        )}
      </p>
    </div>
  );
};

export default TruncatedText;
