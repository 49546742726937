import React, { useState, useMemo, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// Ensure worker is loaded before any PDF operations
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`,
  window.location.origin
).toString();

const PDFViewer = ({ url, containerStyle }) => {
  const containerRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageSize, setPageSize] = useState({ width: null, height: null });
  const [containerSize, setContainerSize] = useState({
    width: Math.min(window.innerWidth * 0.98, 1600), // Increased max width to 1600px and percentage to 98%
    height: Math.min(window.innerHeight * 0.8, 900),
  });
  const [loading, setLoading] = useState(true);
  const [parentSize, setParentSize] = useState(null);

  const options = useMemo(
    () => ({
      cMapUrl: "cmaps/",
      cMapPacked: true,
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
    }),
    []
  );

  // Monitor parent container size
  useEffect(() => {
    const updateParentSize = () => {
      if (containerRef.current?.parentElement) {
        const parent = containerRef.current.parentElement;
        const rect = parent.getBoundingClientRect();
        setParentSize({
          width: rect.width,
          height: rect.height,
        });
      }
    };

    updateParentSize();
    const resizeObserver = new ResizeObserver(updateParentSize);
    if (containerRef.current?.parentElement) {
      resizeObserver.observe(containerRef.current.parentElement);
    }

    return () => resizeObserver.disconnect();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  const calculateScale = (width, height, parentWidth) => {
    let scale;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (windowWidth <= 768) {
      // Mobile and tablet
      scale = Math.min(
        (parentWidth || windowWidth * 0.98) / width, // Increased from 0.95 to 0.98
        (windowHeight * 0.8) / height
      );
    } else if (windowWidth <= 1024) {
      // Small desktop
      scale = Math.min(
        (parentWidth || windowWidth * 0.9) / width, // Increased from 0.8 to 0.9
        (windowHeight * 0.85) / height
      );
    } else {
      // Large desktop
      scale = Math.min(
        (parentWidth || windowWidth * 0.85) / width, // Increased from 0.7 to 0.85
        (windowHeight * 0.9) / height
      );
    }
    return scale;
  };

  const onPageLoadSuccess = ({ width, height }) => {
    if (!pageSize.width || !pageSize.height) {
      setPageSize({ width, height });
      const scale = calculateScale(width, height, parentSize?.width);

      setContainerSize({
        width: Math.floor(width * scale),
        height: Math.floor(height * scale),
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (pageSize.width && pageSize.height) {
        const scale = calculateScale(
          pageSize.width,
          pageSize.height,
          parentSize?.width
        );

        setContainerSize({
          width: Math.floor(pageSize.width * scale),
          height: Math.floor(pageSize.height * scale),
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial calculation
    return () => window.removeEventListener("resize", handleResize);
  }, [pageSize, parentSize]);

  const scaledDimensions = useMemo(() => {
    if (!pageSize.width) return null;
    return {
      width: containerSize.width,
      height: containerSize.height,
    };
  }, [pageSize, containerSize]);

  return (
    <>
      {loading && <div>Loading PDF...</div>}
      <div
        ref={containerRef}
        style={{
          width: `${containerSize.width}px`,
          height: `${containerSize.height * 0.87}px`,
          overflow: "hidden",
          border: "1px solid #ccc",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          opacity: loading ? 0 : 1,
          transition: "width 0.3s, height 0.3s",
          margin: "0 auto",
          maxWidth: "100%",
          ...containerStyle,
        }}
      >
        <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            loading={<div>Loading PDF...</div>}
            error={<div>Failed to load PDF</div>}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={true}
                renderAnnotationLayer={true}
                width={scaledDimensions?.width}
                onLoadSuccess={index === 0 ? onPageLoadSuccess : undefined}
                error={<div>Error loading page {index + 1}</div>}
              />
            ))}
          </Document>
        </div>
      </div>
    </>
  );
};

export default PDFViewer;
