import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../../shared/Spinner";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import { formatDate, truncate } from "../../../utils";
import { FORM_OPTIONS } from "../../Jobs/table.config";
import Info from "./Info";
import { toast } from "react-toastify";
import DataTable from "./DataTable";
import PayoutLayout from "../../../layouts/PayoutLayout";
import Totals from "./Totals";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";
import { Badge, EditableText } from "../../../shared";
import { useCrumbs } from "../../../hooks/useCrumbs";
import { useConfig } from "../../../hooks";
import { getPdfConfig } from "./table.configs/";
import {
  useDeletePayoutsMutation,
  useGetPayoutQuery,
  useUpdatePayoutMutation,
} from "../../../redux/services/payouts/payoutsApi";

const PayoutsShow = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();
  const crumbs = useCrumbs();
  const [loading, setLoading] = useState(true);
  const { appType } = useConfig();
  const defaultConfig = {
    COLUMN_DEFINITIONS: [],
    TOTAL_COLUMN_DEFINITIONS: [],
  };
  const { pdfConfig = defaultConfig } = getPdfConfig(appType) || {};
  const columnDefs = pdfConfig?.COLUMN_DEFINITIONS || [];
  // const totalColumnDefs = pdfConfig?.TOTAL_COLUMN_DEFINITIONS || [];

  const { form, clearForm, updateForm, setFormInit } = useForm(FORM_OPTIONS);

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  // Get payout query
  const { data: payoutData, isLoading } = useGetPayoutQuery(
    { id: params.id },
    { skip: !params.id }
  );
  const payout = payoutData || {};

  const [updatePayout, { isLoading: isUpdating }] = useUpdatePayoutMutation();
  const [deletePayouts, { isLoading: isDeleting }] = useDeletePayoutsMutation();

  useEffect(() => {
    crumbs.setLoading(true);
  }, []);

  useEffect(() => {
    if (payout._id) {
      const init = {
        ...payout,
        emptyRate: payout.emptyRate,
        mileageRate: payout.mileageRate,
        hourlyRate: payout.hourlyRate,
      };

      setFormInit(init);
      setTimeout(() => setLoading(false), 500);

      crumbs.updateCrumbName({
        name: payout.payoutId,
        path: "/payouts/" + payout._id,
      });
      crumbs.setLoading(false);
    }
  }, [payout._id]);

  async function handleUpdate(e) {
    setLoading(true);

    const update = {
      data: { [e.id]: e.value },
      id: payout._id,
    };
    if (typeof e.legacy === "boolean" && !e.legacy) {
      update.legacy = e.legacy;
    }
    if (e.data) {
      update.data = { ...update.data, ...e.data };
    }

    const res = await updatePayout(update).unwrap();
    setLoading(false);
    toast(res.message, {
      type: res.status,
      toastId: "update-payout",
    });
  }

  // async function handleDelete(ids) {
  //   const res = await { data: ids };

  //   toast(res.message, {
  //     type: res.status,
  //     toastId: "payout-deleted",
  //   });
  //   if (res.status === "success") {
  //     navigate("/payouts");
  //   }
  // }

  function handleTabClick(_e, _item, path) {
    // location.hash = e.target.innerHTML;
    navigate(path);
  }

  const statusColors = { Paid: "green", Sent: "yellow", Review: "orange" };

  // const stage = getStage(invoice.status);
  const title = (
    <div className="text-gray-800 font-semibold text-lg flex items-center">
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id="orderNumber"
        value={payout.payoutId}
        size="md"
        displayText={(v) => (
          <span className="text-gray-800 font-semibold text-lg">{v}</span>
        )}
        loading={loading}
        onSave={(e) => handleUpdate({ value: e.value, id: "payoutId" })}
      />
      <Badge
        color={statusColors[payout.status]}
        label={payout.status}
        size="lg"
        className="ml-2"
      />
      {payout.manifestId && (
        <Badge
          link={`/manifests/${payout.manifestId._id}`}
          label={truncate(`Created from: ${payout.manifestId.manifestId}`, 26)}
          size="lg"
          className="ml-2"
          color="blue"
        />
      )}
    </div>
  );

  const tabs = [
    {
      id: "Info",
      content: (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 gap-4">
            {payout._id && (
              <PayoutLayout
                columns={columnDefs}
                appType={appType}
                // totalColumns={totalColumnDefs}
                payout={payout}
              >
                <Info
                  appType={appType}
                  loading={loading}
                  updateForm={updateForm}
                  setLoading={setLoading}
                  clearForm={clearForm}
                  onFormChange={onFormChange}
                  form={form}
                  handleUpdate={handleUpdate}
                  info={payout}
                />
                <Totals
                  form={form}
                  appType={appType}
                  handleUpdate={handleUpdate}
                  info={payout}
                />
              </PayoutLayout>
            )}
            {payout._id && (
              <>
                <DataTable data={payout} loading={loading} />
              </>
            )}
          </div>
        </div>
      ),
    },
  ];

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderTabHeader = (headerProps) => {
    return (
      <div ref={headerRef}>
        <header className="flex items-center justify-between px-3 py-2 text-semibold text-gray-100">
          <div>{headerProps.title}</div>
          <div className="flex items-center space-x-3">
            <div className="md:flex items-center space-x-3 text-gray-800 hidden">
              {headerProps.actions}
            </div>
          </div>
        </header>
        <div className="flex justify-between md:hidden space-x-5 px-3 py-3 bg-white border-b">
          {headerProps.actions}
        </div>
      </div>
    );
  };

  const actions = (
    <>
      <span className="mr-2">
        Created: {formatDate(payout.dateCreated, null, true)}
      </span>
      <span>Updated: {formatDate(payout.dateUpdated, null, true)}</span>
    </>
  );

  return (
    <SidebarContainer
      title={title}
      hideSidebar
      header={renderTabHeader}
      actions={actions}
    >
      {({ parentHeight }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <div style={wrapperStyle} className="h-auto w-full">
            {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Tabs
                className={`w-full overflow-hidden`}
                style={wrapperStyle}
                panelStyle={panelStyle}
                useSearchParams
                panelClasses={`p-2 border-t overflow-y-scroll`}
                tabListClasses="bg-white px-2 h-[38px]"
                tabclassName="mx-1"
                id="job-show"
                onClick={handleTabClick}
                data={tabs}
              />
            )}
          </div>
        );
      }}
    </SidebarContainer>
  );
};

export default PayoutsShow;
