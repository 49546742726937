import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { getTableConfig } from "./table.config";
import {
  useDeletePayoutsMutation,
  useGetPayoutsQuery,
  useLazyGetPayoutQuery,
  useUpdatePayoutMutation,
} from "../../redux/services/payouts/payoutsApi";
import { usePrefetch } from "../../redux/api";
import { renderCustomColumnDefs } from "../../shared/Table/table.utils";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import PayoutLayout from "../../layouts/PayoutLayout";
import { createRoot } from "react-dom/client";
import CreateModal from "./CreateModal";
import { useConfig } from "../../hooks";

const Payouts = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { appType } = useConfig();
  const { COLUMN_DEFINITIONS } = getTableConfig(appType);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [triggerGetPayout] = useLazyGetPayoutQuery();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleDownloadPDF = useCallback(async (row) => {
    try {
      const container = document.createElement("div");
      container.className = "pdf-container";
      document.body.appendChild(container);

      const root = createRoot(container);

      await new Promise((resolve) => {
        root.render(
          <PayoutLayout
            payout={row}
            columns={PDF_COLUMN_DEFINITIONS}
            download={true}
            onComplete={resolve}
          />
        );
      });

      // Clean up after PDF generation is complete
      root.unmount();
      document.body.removeChild(container);
    } catch (error) {
      console.error("Download failed:", error);
      toast.error("Failed to generate PDF");
    }
  }, []);

  const newTableData = [
    ...COLUMN_DEFINITIONS,
    // {
    //   id: "download",
    //   header: "Download",
    //   accessorKey: "download",
    //   cell: ({ row }) => {
    //     return (
    //       <div
    //         className="flex justify-center cursor-pointer hover:text-primary-600"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           handleDownloadPDF(row.original);
    //         }}
    //         title="Download PDF"
    //       >
    //         <ArrowDownTrayIcon className="w-5 h-5" />
    //       </div>
    //     );
    //   },
    // },
  ];

  const NEW_COLUMN_DEFINITIONS = renderCustomColumnDefs(newTableData);

  const { data, isLoading, isFetching } = useGetPayoutsQuery({
    page: pageIndex + 1,
    limit: pageSize,
  });

  const [updatePayout, { isLoading: isUpdating }] = useUpdatePayoutMutation();
  const [deletePayouts, { isLoading: isDeleting }] = useDeletePayoutsMutation();

  const prefetchPage = usePrefetch("getPayouts");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  function handleEdit(itemId) {
    navigate(`/payouts/${itemId}`);
  }

  async function handleDelete(items) {
    const res = await deletePayouts({ data: items.map((item) => item._id) });

    toast(res.data.message, {
      type: res.data.status,
      toastId: "delete-payouts",
    });

    return res.data;
  }

  async function handleUpdate(e, setLoading) {
    const updateData = {
      id: e.row.original._id,
      data: e.data,
    };

    const res = await updatePayout(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "index-delete-payouts",
    });

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const cannotCreateOrEdit =
    user.authority === "client" || user.authority === "user";

  return (
    <>
      <TableLayout
        id="payouts"
        loading={isLoading || isFetching}
        columns={NEW_COLUMN_DEFINITIONS}
        data={data?.payouts}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        // handleDownloadPDF={handleDownloadPDF}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        onUpdate={handleUpdate}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
      />
      <CreateModal
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
      />
    </>
  );
};

export default Payouts;
