import useForm from "../../hooks/useForm";
import { AddressForm } from "./";
import { Button, Modal } from "../../shared";
import { useEffect } from "react";

export const AddressModal = ({
  visible,
  setVisible,
  title,
  data = {},
  submit,
  loading,
  setLoading = () => false,
}) => {
  const formHook = useForm();

  useEffect(() => {
    if (visible && data?._id) {
      formHook.setFormInit(data);
    }
  }, [data?._id, visible]);

  function handleCancelModal() {
    setVisible(false);
    setLoading(false);
  }

  const modalFooter = (
    <>
      <Button onClick={handleCancelModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={loading}
        disabled={loading}
        onClick={() => submit(formHook.form)}
        type="primary"
      >
        Submit
      </Button>
    </>
  );
  const modalContent = (
    <div>
      <AddressForm {...formHook} />
    </div>
  );
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={() => setVisible(false)}
      title={title || "Address"}
      footer={modalFooter}
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};
