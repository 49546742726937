import { useEffect, useState } from "react";
import Input from "../../../../shared/Input";
import SettingsPanel from "../../../../shared/SettingsPanel";
import useForm from "../../../../hooks/useForm";
import _ from "lodash";
import { mapValues } from "radash";
import { useAuth } from "../../../../hooks/useAuth";
import { useUpdateClientMutation } from "../../../../redux/services/clients/clientsApi";
import { AddressModal } from "../../../../components";
import { toast } from "react-toastify";

const GeneralSettings = (props) => {
  const { user } = useAuth();
  const { form, setFormInit, clearForm } = useForm();
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [updateClient] = useUpdateClientMutation();

  useEffect(() => {
    setFormInit({
      slug: { data: props.client?.slug },
      name: { data: props.client?.name },
      email: { data: props.client?.email },
      phone: { data: props.client?.phone },
      address: { data: props.client?.address?.fullAddress },
      coowner: { data: props.client?.coowner },
    });
  }, [props.client?._id, props.client?.address]);

  async function handleSave() {
    try {
      const edited = _.pickBy(form, function (obj, key) {
        return obj.updated;
      });
      const res = mapValues(edited, (value) => value.data);

      if (Object.keys(res).length === 0) return;

      const updateData = {
        data: res,
        parentId: user.parentCompany,
        id: props.client._id,
      };

      if (res.address) {
        updateData.type = "address";
      }

      const result = await updateClient(updateData).unwrap();

      if (result.status === "success") {
        toast.success(result.message || "Client updated successfully");
      } else {
        toast.error(result.message || "Failed to update client");
      }
    } catch (error) {
      console.error("Error saving client:", error);
      toast.error("Failed to save changes");
    }
  }

  async function submitAddress(data) {
    try {
      setModalLoading(true);
      const updateData = {
        city: data.city,
        state: data.state,
        country: data.country,
        address: data.address,
        street: data.address,
        zipcode: data.zipcode,
        type: "address",
      };

      const res = await updateClient({
        id: props.client._id,
        data: updateData,
      }).unwrap();

      if (res.status === "success") {
        setAddressModalVisible(false);
        setFormInit({
          address: { data: res.data.address?.fullAddress },
        });
        toast.success(res.message);
      } else {
        toast.error(res.message || "Failed to update address");
      }
    } catch (error) {
      console.error("Error updating address:", error);
      toast.error("Failed to update address");
    } finally {
      setModalLoading(false);
    }
  }

  function onFormChange(e) {
    setFormInit({ [e.id]: { data: e.value, updated: true } });
  }

  const data = [
    {
      title: "Client Slug",
      subtitle: "Unique identifier for this client",
      content: (
        <Input
          id="slug"
          onChange={onFormChange}
          value={form.slug?.data}
          size="md"
          placeholder="Client slug"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Client Name",
      subtitle: "Client companies name",
      content: (
        <Input
          id="name"
          onChange={onFormChange}
          value={form.name?.data}
          size="md"
          placeholder="Client Name"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Client Email",
      subtitle: "The main email for the client",
      content: (
        <Input
          id="email"
          onChange={onFormChange}
          value={form.email?.data}
          size="md"
          type="email"
          placeholder="example@123.com"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Client Address",
      subtitle: "The clients address",
      content: (
        <Input
          id="address"
          onChange={onFormChange}
          value={form.address?.data}
          size="md"
          placeholder="Address"
          readOnly
          onClick={() => setAddressModalVisible(true)}
        />
      ),
    },
    {
      title: "Phone Number",
      subtitle: "The main contact for the client",
      content: (
        <Input
          id="phone"
          onChange={onFormChange}
          value={form.phone?.data}
          size="md"
          type="tel"
          placeholder="(555)555-5555"
          onBlur={handleSave}
        />
      ),
    },
    {
      title: "Co-Owner",
      subtitle: "The clients co-owner",
      content: (
        <Input
          id="coowner"
          onChange={onFormChange}
          value={form.coowner?.data}
          size="md"
          placeholder="Client Co-Owner"
          onBlur={handleSave}
        />
      ),
    },
  ];

  return (
    <>
      <div className="space-y-4">
        <SettingsPanel title="Basic Info" data={data} />
      </div>
      <AddressModal
        visible={addressModalVisible}
        setVisible={setAddressModalVisible}
        data={props.client.address}
        submit={submitAddress}
        loading={modalLoading}
        setLoading={setModalLoading}
      />
    </>
  );
};

export default GeneralSettings;
