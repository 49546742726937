import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import employeeTable from "./employee.table.config";
// import orgTable from "./org.table.config";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { useAuth } from "../../hooks/useAuth";
// import {
//   fetchEmployees,
//   resetEmployees,
//   selectAllEmployees,
// } from "../../redux/reducers/employees/employeesSlice";
import EmployeeCreateModal from "./EmployeeCreateModal";
// import OrgCreateModal from "../Organizations/OrgCreateModal";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Dropdown from "../../shared/Dropdown";
import {
  fetchOrganizations,
  resetOrganizations,
} from "../../redux/reducers/organizations/organizationsSlice";
import {
  useGetEmployeesQuery,
  useUpdateEmployeeMutation,
} from "../../redux/services/employees/employeesApi";
import { usePrefetch } from "../../redux/api";

const Employees = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [type, setType] = useState("Employees");
  const isEmployees = type === "Employees";
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const [refreshKey, setRefreshKey] = useState(0);

  const {
    data: employeesResponse,
    isLoading,
    isFetching,
  } = useGetEmployeesQuery(
    {
      page: pageIndex + 1,
      limit: pageSize,
      refreshKey,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
      refetchOnFocus: true,
    }
  );

  const employees = employeesResponse?.employees || [];

  const pagination = {
    pageIndex: (employeesResponse?.data?.page || 1) - 1, // TanStack Table uses 0-based index
    pageSize: employeesResponse?.data?.limit || 10,
    pageCount: employeesResponse?.data?.totalPages || 0,
    totalRows: employeesResponse?.data?.count || 0,
  };

  const prefetchPage = usePrefetch("getEmployees");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: employeesResponse?.data?.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, employeesResponse?.data?.nextPage, pageSize]);

  useEffect(() => {
    if (
      employeesResponse?.data?.hasNext &&
      employeesResponse?.data.nextPage <= employeesResponse?.data.totalPages
    ) {
      prefetchNext();
    }
  }, [employeesResponse, pageIndex, prefetchNext]);

  const handleEmployeeCreated = useCallback((newEmployee) => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  async function handleUpdate(e, setLoading) {
    const updateData = {
      id: e.row.original._id,
      data: e.data,
    };

    const res = await updateEmployee(updateData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: `update-employee-${res.status}`,
    });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const formTypeOptions = [
    {
      value: "Employees",
      //   onClick: handleTypeChange,
      label: "Employees",
    },
    // {
    //   value: "Organizations",
    //   onClick: handleTypeChange,
    //   label: "Organizations",
    // },
  ];

  const formTypeTrigger = (
    <div className="text-2xl font-bold flex items-center cursor-pointer">
      {type}
      <ChevronDownIcon className="h-3 w-3 ml-1" />
    </div>
  );

  const actions = (
    <div>
      <Dropdown
        align="left"
        closeOnClick
        trigger={formTypeTrigger}
        data={formTypeOptions}
      />
    </div>
  );

  return (
    <>
      <TableLayout
        mainAction={{ onClick: () => setModalVisible(true) }}
        id="employees"
        title={actions}
        loading={isLoading || isFetching}
        columns={
          isEmployees
            ? employeeTable.COLUMN_DEFINITIONS
            : orgTable.COLUMN_DEFINITIONS
        }
        onUpdate={handleUpdate}
        data={employees}
        pagination={pagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPaginationChange={setPagination}
        pageSizeOptions={
          isEmployees
            ? employeeTable.PAGE_SIZE_OPTIONS
            : orgTable.PAGE_SIZE_OPTIONS
        }
      />
      {isEmployees ? (
        <EmployeeCreateModal
          visible={modalVisible}
          setVisible={setModalVisible}
          onEmployeeCreated={handleEmployeeCreated}
        />
      ) : (
        <OrgCreateModal visible={modalVisible} setVisible={setModalVisible} />
      )}
    </>
  );
};

export default Employees;
