export const AddedPartsList = ({ addedParts, setAddedParts }) => {
  return (
    <div className="mt-4">
      <h4 className="text-md font-semibold mb-2">Added Parts</h4>
      <ul className="divide-y divide-gray-200">
        {addedParts.map((part, index) => (
          <li key={index} className="py-2">
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <p className="font-medium">{part.name}</p>
                <div className="text-sm text-gray-600 grid grid-cols-2 gap-2">
                  {part.partNumber && (
                    <span>Part Number: {part.partNumber}</span>
                  )}
                  {part.description && (
                    <span>Description: {part.description}</span>
                  )}
                  {part.quantity && <span>Quantity: {part.quantity}</span>}
                  {part.unitPrice && (
                    <span>
                      Unit Price: $
                      {typeof part.unitPrice === "number"
                        ? part.unitPrice.toFixed(2)
                        : Number(part.unitPrice).toFixed(2)}
                    </span>
                  )}
                </div>
              </div>
              <button
                onClick={() => {
                  setAddedParts((prev) => prev.filter((_, i) => i !== index));
                }}
                className="p-1.5 text-gray-400 hover:text-red-500 transition-colors rounded-full hover:bg-gray-100"
                title="Remove part"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </li>
        ))}
        <div className="pt-2 text-right text-gray-700">
          <p className="font-medium">
            Total: $
            {addedParts
              .reduce(
                (sum, part) =>
                  sum + (part.quantity || 0) * (part.unitPrice || 0),
                0
              )
              .toFixed(2)}
          </p>
        </div>
      </ul>
    </div>
  );
};
