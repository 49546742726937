import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../shared/Table/table.utils";
import { formatDate, renderAmount } from "../../utils";
import Badge from "../../shared/Badge";
import { isAuthorized } from "../../utils/authority";

// Config for table and form
const tableConfig = [
  // {
  //   id: "name",
  //   accessorKey: "name",
  //   visible: true,
  //   required: true,
  //   minWidth: 140,
  //   cell: ({ getValue, row: { original } }) => (
  //     <Link
  //       className="text-primary-600 hover:underline"
  //       to={`/inventory/${original._id}`}
  //     >
  //       {getValue() || original.partNumber}
  //     </Link>
  //   ),
  // },
  {
    id: "partNumber",
    accessorKey: "partNumber",
    visible: true,
    minWidth: 120,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "category",
    accessorKey: "category",
    visible: true,
    minWidth: 160,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "description",
    accessorKey: "description",
    visible: true,
    minWidth: 200,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "quantity",
    accessorKey: "quantity",
    visible: true,
    minWidth: 100,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "unitPrice",
    accessorKey: "unitPrice",
    visible: true,
    minWidth: 120,
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    id: "status",
    accessorKey: "status",
    visible: true,
    minWidth: 120,
    cell: ({ getValue }) => (
      <Badge
        color={getValue() === "In Stock" ? "green" : "yellow"}
        label={getValue()}
      />
    ),
  },
  // {
  //   id: "supplier",
  //   accessorKey: "supplier",
  //   visible: true,
  //   minWidth: 160,
  //   cell: ({ getValue }) => {
  //     const supplier = getValue();
  //     return supplier?.name || supplier || "-";
  //   },
  // },
  {
    id: "dateCreated",
    accessorKey: "dateCreated",
    visible: false,
    cell: ({ getValue }) => formatDate(getValue(), null, true),
    disableGlobalFilter: true,
  },
  {
    id: "dateUpdated",
    accessorKey: "dateUpdated",
    visible: false,
    cell: ({ getValue }) => formatDate(getValue(), null, true),
    disableGlobalFilter: true,
  },
  {
    id: "_id",
    header: "id",
    visible: false,
    disableGlobalFilter: true,
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
// export const FORM_OPTIONS = {
//   validations: renderValidations(tableConfig, "inventory"),
// };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Items", value: 10 },
  { label: "20 Items", value: 20 },
  { label: "50 Items", value: 50 },
  { label: "100 Items", value: 100 },
  { label: "150 Items", value: 150 },
];
