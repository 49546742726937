import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { endOfDay, startOfDay } from "date-fns";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import { useConfig } from "../../../hooks";
import { AvatarClient } from "../../../components";
import { ClientsDropdown } from "../../../components/ClientsDropdown";
import { useAddInvoiceMutation } from "../../../redux/services/invoices/invoicesApi";
import { useGetClientsQuery } from "../../../redux/services/clients/clientsApi";
import { useGetJobCountByClientQuery } from "../../../redux/services/jobs/jobsApi";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import { Input } from "../../../shared";
import DateRangePicker from "../../../shared/DatePicker/DateRange";
import "./CreateModal.css";
import request, { renderQueries } from "../../../utils/request";

/**
 * Modal component for creating new invoices
 * @param {Object} props - Component props
 * @param {boolean} props.modalOpen - Controls modal visibility
 * @param {Function} props.setModalOpen - Function to set modal visibility
 * @param {Object} props.client - Optional pre-selected client
 */
const CreateModal = ({ modalOpen, setModalOpen, ...props }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit, errors } = useForm();
  const { appType } = useConfig();

  const [recentClients, setRecentClients] = useState([]);

  // RTK Query hooks
  const [addInvoice, { isLoading: addingInvoice }] = useAddInvoiceMutation();
  const jobCount = useGetJobCountByClientQuery({});
  const { data: clientsData } = useGetClientsQuery({ limit: 30 });

  const clients = clientsData?.clients || [];

  // Get recent clients on mount
  useEffect(() => {
    if (clients.length > 0) {
      let clientData = clients;
      if (clients.length > 5) {
        clientData = clients.slice(0, 5);
      }
      setRecentClients(clientData);
    }
  }, [clients]);

  // Set initial client if provided
  useEffect(() => {
    if (props.client && modalOpen) {
      setFormInit({ client: props.client });
    }
  }, [props.client, modalOpen, setFormInit]);

  // Handle date range changes

  function getQuery() {
    return {
      appType: appType,
      parent: user.parentCompany,
      client: { _id: form.client._id, slug: form.client.slug },
      invoiceId: null,
      route: true,
      startDate: form.startDate
        ? startOfDay(form.startDate).toISOString()
        : null,
      endDate: form.endDate ? endOfDay(form.endDate).toISOString() : null,
      legacy: false,
    };
  }

  const handleDateRangeChange = ([start, end]) => {
    updateForm((prevForm) => ({
      ...prevForm,
      startDate: start ? start.toISOString() : null,
      endDate: end ? end.toISOString() : null,
    }));
  };

  const handleDateUpdate = () => {
    const query = getQuery();
    getCount(query);
  };

  // Get job count when date range or client changes
  async function getCount(query) {
    query.countOnly = true;
    query.client = query.client._id;
    const res = await request(`/jobs?${renderQueries(query)}`);
    updateForm({ id: "count", value: res.data.count });
  }

  useEffect(() => {
    if (form.startDate && form.endDate && form.client) {
      handleDateUpdate();
    }
  }, [form.startDate, form.endDate, form.client]);

  function handleSelectClient(client) {
    updateForm({ id: "client", value: client });
  }

  function handleCancel() {
    clearForm("client");
  }

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  async function createInvoice() {
    const postData = {
      ...getQuery(),
      useLegacyCreate: false,
    };

    const res = await addInvoice(postData).unwrap();

    toast(res.message, {
      type: res.status,
      toastId: "add-invoice",
    });

    if (res.status === "success") {
      navigate("/invoices/" + res.data._id);
    }
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      <Button
        loading={addingInvoice}
        disabled={form.count === 0 || addingInvoice}
        onClick={createInvoice}
        type="primary"
      >
        Submit
      </Button>
    </div>
  );

  const createModalContent = (
    <div className="">
      {!form.client ? (
        <div className="space-y-3">
          <ClientsDropdown
            onClick={(e) => handleSelectClient(e)}
            onChange={(e) => handleSelectClient(e)}
            value={form.client}
            form={form}
            errors={errors}
            updateForm={updateForm}
          />
          {!form.newClient && (
            <>
              <div className="flex items-center justify-between">
                <div>Recent clients</div>
              </div>
              <div>
                {recentClients.map((recent, i) => (
                  <AvatarClient
                    key={i}
                    disableCancel
                    onClick={() => handleSelectClient(recent)}
                    {...recent}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="mb-5">
            <AvatarClient selected onCancel={handleCancel} {...form.client} />
          </div>
          <div className="mb-5 items-center gap-4 mx-5 grid grid-cols-1 w-1/2 md:w-full md:grid-cols-3">
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date Range
              </label>
              <DateRangePicker
                startDate={form.startDate ? new Date(form.startDate) : null}
                endDate={form.endDate ? new Date(form.endDate) : null}
                onChange={([start, end]) => {
                  updateForm((prevForm) => ({
                    ...prevForm,
                    startDate: start ? start.toISOString() : null,
                    endDate: end ? end.toISOString() : null,
                  }));
                }}
                className="w-full"
              />
            </div>
            <div className="">
              <Input
                disabled
                className="!w-1/2"
                label="Job Count:"
                value={form.count || 0}
              />
            </div>
          </div>
          {form.count === 0 && form.startDate && form.endDate && (
            <p className="text-red-500 text-sm text-center">
              There are no jobs in this selected date range.
            </p>
          )}
        </>
      )}
    </div>
  );

  const title = <span className="mx-4">Select a client</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};

export default CreateModal;
