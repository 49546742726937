import { useState, useEffect, useMemo } from "react";
import { partCategoryOptions } from "../constants/categories";
import { useDebounceSearch, useForm } from "../hooks";
import {
  useAddInventoryItemMutation,
  useGetInventoryItemsQuery,
} from "../redux/services/inventory/inventoryApi";
import { Modal } from "../shared";
import { EquipmentCard } from "./EquipmentCard";
import { AddedPartsList } from "./PartsList/AddedPartsList";
import AddPartForm from "./PartsList/AddPartForm";

const PartsModal = ({ visible, setVisible, onSubmit, equipment, onClose }) => {
  const { form, updateForm, clearForm, errors, setFormInit } = useForm();
  const { search, handleSearch, clearSearch } = useDebounceSearch();
  const [partsList, setPartsList] = useState([]);
  const [addedParts, setAddedParts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [newPart, setNewPart] = useState({ model: "", serial: "", make: "" });
  const [showNewPartInputs, setShowNewPartInputs] = useState(false);
  const [showExistingPartSelect, setShowExistingPartSelect] = useState(false);
  const [isPartsListLoading, setIsPartsListLoading] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [visibleInputs, setVisibleInputs] = useState({
    category: true,
    name: true,
    partNumber: false,
    description: false,
    quantity: false,
    unitPrice: false,
  });

  const [addInventoryItem] = useAddInventoryItemMutation();

  const {
    data: inventoryItems,
    isLoading: inventoryLoading,
    isFetching: inventoryFetching,
  } = useGetInventoryItemsQuery(
    {
      page: pageIndex + 1,
      limit: pageSize,
      search: search.useSearch,
      searchTerm: search.term,
      // equipment: {
      //   manufacturer: equipment?.manufacturer,
      //   model: equipment?.model,
      //   serialNumber: equipment?.serialNumber,
      // },
    },
    {
      // refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (inventoryItems) {
      setPartsList(inventoryItems);
      setIsPartsListLoading(false);
    }
  }, [inventoryItems]);

  const partOptions = useMemo(() => {
    if (inventoryLoading || inventoryFetching || !inventoryItems?.inventory) {
      return [];
    }

    return inventoryItems.inventory.map((item) => ({
      fullValue: item,
      value: item.id,
      label: (
        <div className="flex flex-col text-sm">
          <div>{item.name}</div>
          <div className="text-gray-500">
            {item.partNumber} • {item.description}
          </div>
        </div>
      ),
    }));
  }, [inventoryLoading, inventoryFetching, inventoryItems]);

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize: 20 });
    handleSearch(value);
  }

  const handlePartCreated = (part) => {
    handleFormChange({
      id: "part",
      value: part,
    });
    setPartModalOpen(false);
  };

  const getNextInput = (currentInput) => {
    const inputOrder = [
      "category",
      "name",
      "partNumber",
      "description",
      "quantity",
      "unitPrice",
    ];
    const currentIndex = inputOrder.indexOf(currentInput);
    return inputOrder[currentIndex + 1];
  };

  const handleCloseModal = () => {
    onClose?.();
    clearForm();
    setVisible(false);
    setAddedParts([]);
  };

  const handleSubmit = () => {
    clearSearch();
    onSubmit?.(addedParts);
    handleCloseModal();
  };

  const handleSelectChange = (selectedValue, fieldName, existingParts) => {
    if (selectedValue) {
      const { fullValue, label, value } = selectedValue;

      if (fieldName === "category") {
        setSelectedCategory(selectedValue);
        updateForm({ id: fieldName, value: fullValue });
        if (existingParts) {
          setPartsList(inventoryItems);
        }
      } else if (fieldName === "part") {
        // Find the selected part from the partsList
        const selectedPart = inventoryItems.inventory.find(
          (part) => part.id === value
        );
        setSelectedPart(selectedPart);
        setFormInit({
          [fieldName]: selectedPart,
          quantity: 1,
          unitPrice: selectedPart?.unitPrice,
        });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event || {};
    if (!name) return; // Exit if name is undefined

    updateForm({ id: name, value });
    setNewPart((prev) => ({ ...prev, [name]: value }));

    if (value.trim() !== "") {
      setVisibleInputs((prev) => {
        const nextInput = getNextInput(name);
        return { ...prev, [nextInput]: true };
      });
    }
  };

  const handleAddPart = async () => {
    if (selectedPart) {
      setAddedParts((prev) => [
        ...prev,
        {
          ...selectedPart,
          quantity: form.quantity,
          unitPrice: form.unitPrice,
        },
      ]);
    } else if (newPart) {
      const newInventoryItem = {
        name: form.name,
        partNumber: form.partNumber,
        description: form.description,
        category: form.category?.fullValue || selectedCategory?.fullValue,
        quantity: form.quantity,
        unitPrice: form.unitPrice,
        supplier: form.supplier,
      };

      const response = await addInventoryItem(newInventoryItem);

      setAddedParts((prev) => [
        ...prev,
        {
          ...newPart,
          ...form,
          category: form.category?.fullValue || selectedCategory?.fullValue,
        },
      ]);
    }

    setSelectedPart(null);
    clearForm();
  };

  const modalContent = (
    <div className="h-full">
      {equipment && <EquipmentCard equipment={equipment} />}
      {addedParts.length > 0 && (
        <AddedPartsList addedParts={addedParts} setAddedParts={setAddedParts} />
      )}
      {/* <AddPartsButton
        addExisting={handleShowExistingPartSelect}
        addNew={handleShowNewPartInputs}
      />
      {showExistingPartSelect && (
        <ExistingPartsForm
          form={form}
          selectedCategory={selectedCategory}
          isPartsListLoading={isPartsListLoading}
          inventoryLoading={inventoryLoading}
          partsList={partsList}
          selectedPart={selectedPart}
          handleSelectChange={handleSelectChange}
          handleInputChange={handleInputChange}
          handleAddPart={handleAddPart}
        />
      )}
      {showNewPartInputs && (
        <NewPartsForm
          form={form}
          handleSelectChange={handleSelectChange}
          handleInputChange={handleInputChange}
          updateForm={updateForm}
          handleAddPart={handleAddPart}
          categories={categories}
        />
      )} */}
      <AddPartForm
        form={form}
        handleInputChange={handleInputChange}
        categories={partCategoryOptions}
        handleAddPart={handleAddPart}
        handlePartCreated={handlePartCreated}
        handleSelectChange={handleSelectChange}
        partOptions={partOptions}
        errors={errors}
        searchValue={search.term}
        globalFilter={globalFilter}
        handleSubmit={handleSubmit}
      />
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onClose={handleCloseModal}
      confirm={handleSubmit}
      title="Add Part"
      content={modalContent}
      size="md"
      footerPosition="right"
    />
  );
};

export default PartsModal;
