import { getSearchParams } from "../../../utils";
import { api } from "../../api";

/**
 * API slice for handling report-related operations
 */
const reportsApi = api.injectEndpoints({
  tagTypes: ["Reports"],
  endpoints: (builder) => ({
    // Get service report data
    getServiceReport: builder.query({
      query: ({ id, ...params }) => ({
        url: `reports/service/${id}`,
        params,
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Reports", id }],
    }),

    // Generate driver report
    generateDriverReport: builder.mutation({
      query: (reportConfig) => ({
        url: "reports/driver",
        method: "POST",
        body: reportConfig,
      }),
    }),

    // Get job status report data
    getJobStatusReport: builder.query({
      query: ({ type, startDate, endDate }) => ({
        url: "reports/job-status",
        params: {
          type,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        },
      }),
      transformResponse: (response) => {
        // Transform the response to match the chart data format
        return response.data.map((item) => ({
          name: item.Name,
          New: item.New || 0,
          Pending: item.Pending || 0,
          "En Route": item["En Route"] || 0,
          Delivered: item.Delivered || 0,
          Invoiced: item.Invoiced || 0,
          Total: item.Total || 0,
        }));
      },
    }),

    // Get client report data
    getClientReport: builder.query({
      query: ({ clientId, ...params }) => ({
        url: `reports/client/${clientId}`,
        params,
      }),
      providesTags: (_result, _error, { clientId }) => [
        { type: "Reports", id: clientId },
      ],
    }),

    getEmployeeReport: builder.query({
      query: ({ employeeId, ...params }) => ({
        url: `reports/employee/${employeeId}`,
        params,
      }),
    }),

    // Generate manifest report
    generateManifestReport: builder.mutation({
      query: (manifestData) => ({
        url: "reports/manifest",
        method: "POST",
        body: manifestData,
      }),
    }),

    // Generate payout report
    generatePayoutReport: builder.mutation({
      query: (payoutData) => ({
        url: "reports/payout",
        method: "POST",
        body: payoutData,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetServiceReportQuery,
  useGenerateDriverReportMutation,
  useGetDriverReportQuery,
  useGetJobStatusReportQuery,
  useGetClientReportQuery,
  useGetEmployeeReportQuery,
  useGenerateManifestReportMutation,
  useGeneratePayoutReportMutation,
} = reportsApi;

export default reportsApi;
