import { useMemo, createContext, useContext } from "react";
import {
  useUpdateJobMutation,
  useGetJobQuery,
} from "../redux/services/jobs/jobsApi";

const JobContext = createContext({
  data: null,
  loading: false,
  mode: null,
  isQuoteMode: false,
  isServiceMode: false,
});

/**
 * Determines the job mode based on the job status.
 * @param {string} status - The current status of the job.
 * @returns {string} - The job mode: "Quote" or "Service"
 */
export const getJobMode = (status) => {
  if (["Pending", "New"].includes(status)) {
    return "Quote";
  }
  return "Service";
};

export const JobProvider = ({ children, jobId }) => {
  const { data: jobData, isLoading: jobLoading } = useGetJobQuery(
    { jobId, drivers: true },
    { skip: !jobId }
  );
  const [_updateJob, { isLoading: jobUpdating }] = useUpdateJobMutation();

  const jobMode = useMemo(() => getJobMode(jobData?.status), [jobData?.status]);

  const value = useMemo(
    () => ({
      jobId,
      data: jobData,
      loading: jobLoading || jobUpdating,
      mode: jobMode,
      isQuoteMode: jobMode === "Quote",
      isServiceMode: jobMode === "Service",
    }),
    [jobId, jobData, jobLoading, jobUpdating, jobMode]
  );

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export const useJob = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error("useJob must be used within a JobProvider");
  }
  return context;
};
