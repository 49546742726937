import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import { useAuth } from "../../../hooks/useAuth";
import useForm from "../../../hooks/useForm";
import {
  fetchClients,
  selectAllClients,
} from "../../../redux/reducers/clients/clientsSlice";
import request, { renderQueries } from "../../../utils/request";
import InputDropdown from "../../../shared/InputDropdown";
import { useNavigate } from "react-router-dom";
import AdvancedSettings from "./AdvancedSettings";
import classNames from "classnames";
import { useAddJobMutation } from "../../../redux/services/jobs/jobsApi";
import { ServiceJobForm } from "./ServiceJobForm";
import { ClientsDropdown } from "../../../components/ClientsDropdown";
import {
  useAddClientMutation,
  useGetClientsQuery,
  useGetRecentClientsQuery,
} from "../../../redux/services/clients/clientsApi";
import useEmblaCarousel from "embla-carousel-react";
import { toast } from "react-toastify";
import { useConfig } from "../../../hooks";
import { AvatarClient } from "../../../components";

const CreateModal = ({ modalOpen, setModalOpen, ...props }) => {
  const { user } = useAuth();
  const { appType } = useConfig();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm, clearForm, setFormInit, errors } = useForm();
  const [slideInView, setSlideInView] = useState(0);
  // Carousel hook
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    watchDrag: false,
  });

  // const [recentClients, setRecentClients] = useState([]);
  const [submitButton, setSubmitButton] = useState("");

  const [addJob, { isLoading: addingJob }] = useAddJobMutation();
  const [addClient, { isLoading: addingClient }] = useAddClientMutation();
  const { data: clientsData } = useGetClientsQuery({ limit: 30 });
  const { data: recentClientsData } = useGetRecentClientsQuery();

  const clients = clientsData?.clients || [];

  const recentClients = useMemo(
    () => recentClientsData?.data || [],

    [recentClientsData]
  );

  // async function getRecentClients() {
  //   let clientData = clients;
  //   if (clients.length > 5) {
  //     clientData = clients.slice(0, 5);
  //   }
  //   setRecentClients(clientData);
  // }

  function getButtonTitle() {
    return appType === "trucking" ? "Submit" : "Next";
  }

  useEffect(() => {
    setSubmitButton(getButtonTitle());
  }, [appType]);

  // useEffect(() => {
  //   getRecentClients();
  // }, []);

  useEffect(() => {
    if (form.client) {
      setFormInit({ appointmentDate: new Date() });
    }
  }, [form.client]);

  function closeModal() {
    setModalOpen(false);
    handleCancel();
  }

  async function saveJob() {
    const postData = {
      ...form,
      useLegacyCreate: false,
    };

    const res = await addJob(postData).unwrap();

    if (res.status !== "success") {
      toast(res.message, {
        toastId: "add-job-error",
        type: res.status,
      });
      return;
    } else {
      closeModal();
      navigate("/jobs/" + res.data._id);
    }
  }

  const handleCancel = useCallback(() => {
    // setRecentClients([]);
    setSubmitButton(getButtonTitle());
    setSlideInView(0);
    clearForm(["client", "newClient"]);
    if (emblaApi) {
      emblaApi.scrollTo(0);
    }
  }, [emblaApi]);

  // Handle carousel back
  const scrollPrev = useCallback(() => {
    setSubmitButton(getButtonTitle());
    setSlideInView(slideInView - 1);

    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi, slideInView]);

  // Handle carousel next
  const scrollNext = useCallback(() => {
    setSubmitButton("Submit");
    setSlideInView(slideInView + 1);

    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi, slideInView]);

  /**
   * Handles the selection of a client and scrolls to the next view.
   *
   * @param {Object} cl - The client object selected by the user.
   */
  const handleSelectClient = useCallback(
    (cl) => {
      // Updates the form state with the selected client
      updateForm({ id: "client", value: cl });
      // Scrolls to the next view after client selection
      if (appType !== "trucking") {
        scrollNext();
      }
    },
    [scrollNext, updateForm, appType]
  );

  async function addClientAndScrollNext() {
    if (!form.client) {
      toast("Please fill out the client form before proceeding.", {
        toastId: "client-form-error",
        type: "error",
      });
      return;
    }
    // Assuming addClient is a function that sends a request to add a client
    // and returns a promise that resolves to the client's ID if successful
    const clientRes = await addClient(form.client).unwrap();

    toast(clientRes.message, {
      toastId: `add-client-${clientRes.status}`,
      type: clientRes.status,
    });

    if (clientRes.status !== "success") {
      return;
    }

    updateForm({ id: "client", value: clientRes.data });
    scrollNext();
  }

  // Main modal button handler
  function handleSubmit() {
    if (appType !== "trucking") {
      // If main form has client, scroll to next form
      if (form.client && !form.client?._id && emblaApi?.canScrollNext()) {
        addClientAndScrollNext();
        return;
      }
    }

    saveJob();
  }

  const footer = (
    <div>
      <Button className="mr-3" onClick={closeModal} type="alternative">
        Cancel
      </Button>
      {appType !== "trucking" && submitButton === "Submit" && (
        <Button type="alternative" className="mr-2" onClick={scrollPrev}>
          Back
        </Button>
      )}
      <Button
        loading={addingJob}
        disabled={addingJob}
        onClick={handleSubmit}
        type="primary"
      >
        {submitButton}
      </Button>
    </div>
  );

  const createModalContent = useMemo(() => {
    return (
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide" key={0}>
            {!form.client?._id ? (
              <div
                className="space-y-3"
                style={{
                  display: slideInView === 0 ? "block" : "none",
                }}
              >
                <ClientsDropdown
                  onClick={(e) => handleSelectClient(e)}
                  onChange={(e) => handleSelectClient(e)}
                  value={form.client}
                  form={form}
                  errors={errors}
                  updateForm={updateForm}
                />
                {!form.newClient && (
                  <>
                    <div className="flex items-center justify-between">
                      <div>Recent clients</div>
                    </div>
                    <div>
                      {recentClients.map((recent, i) => {
                        return (
                          <AvatarClient
                            key={"av-" + i}
                            disableCancel
                            onClick={() => handleSelectClient(recent)}
                            {...recent}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className="mb-5">
                  <AvatarClient
                    selected
                    onCancel={handleCancel}
                    {...form.client}
                  />
                </div>
                <AdvancedSettings
                  defaultOpen
                  form={form}
                  updateForm={updateForm}
                />
              </>
            )}
          </div>

          <div className="embla__slide" key={1}>
            <div
              style={{
                display: slideInView === 1 ? "block" : "none",
              }}
            >
              <div className="mb-5">
                <AvatarClient
                  selected
                  onCancel={handleCancel}
                  {...form.client}
                />
                <ServiceJobForm form={form} updateForm={updateForm} />
              </div>
              <AdvancedSettings
                defaultOpen
                form={form}
                updateForm={updateForm}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [slideInView, form, clients.length, errors, emblaApi]);

  // const createModalContent = (
  //   <div className="">
  //     {!form.client || !form.newClient ? (
  //       <div className="space-y-3">
  //         <ClientsDropdown
  //           onClick={(e) => handleSelectClient(e)}
  //           onChange={(e) => handleSelectClient(e)}
  //           value={form.client}
  //           form={form}
  //           errors={errors}
  //           updateForm={updateForm}
  //         />
  //         <div className="flex items-center justify-between">
  //           <div>Recent clients</div>
  //         </div>
  //         <div>
  //           {clients.slice(0, 5).map((recent, i) => {
  //             return (
  //               <AvatarClient
  //                 key={i}
  //                 onClick={() => handleSelectClient(recent)}
  //                 {...recent}
  //               />
  //             );
  //           })}
  //         </div>
  //       </div>
  //     ) : (
  //       <>
  //         <div className="mb-5">
  //           <AvatarClient selected onCancel={handleCancel} {...form.client} />
  //           <ServiceJobForm form={form} updateForm={updateForm} />
  //         </div>
  //         <AdvancedSettings defaultOpen form={form} updateForm={updateForm} />
  //       </>
  //     )}
  //   </div>
  // );

  const title = <span className="mx-4">Select a client</span>;

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={closeModal}
      title={title}
      footer={footer}
      content={createModalContent}
      footerPosition="right"
      contentWrapperclassName="p-0"
    />
  );
};

export default CreateModal;
