import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../../shared/Modal";
import { useDeleteEquipmentMutation } from "../../../redux/services/equipment";

export const DeleteEquipmentModal = ({
  visible,
  setVisible,
  equipment,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteEquipment, { isLoading: isDeleting }] =
    useDeleteEquipmentMutation();

  const title = t("Delete Equipment");

  function cannotDeleteEquipment() {
    if (Array.isArray(equipment)) {
      const equipmentArray = equipment;
      let error = false;
      equipmentArray.forEach((item) => {
        // Add conditions specific to equipment that prevent deletion
        // For example:
        if (item.isInUse) {
          error = "Equipment is in use";
          return;
        }
        // Add more conditions as needed
      });
      return error;
    }
  }

  function renderContent() {
    const cannotDelete = cannotDeleteEquipment();
    if (cannotDelete) {
      return t(cannotDelete);
    }

    return t("Are you sure you want to delete this equipment?");
  }

  async function handleDelete() {
    try {
      if (Array.isArray(equipment)) {
        // Handle multiple deletions
        const deletePromises = equipment.map((item) =>
          deleteEquipment({ id: item._id })
            .unwrap()
            .catch((error) => {
              console.error(`Failed to delete equipment ${item._id}:`, error);
              return {
                status: "error",
                message: `Failed to delete ${item.name || item._id}`,
              };
            })
        );

        const results = await Promise.all(deletePromises);
        const allSuccessful = results.every((res) => res.status === "success");

        toast(
          allSuccessful
            ? "All equipment deleted successfully"
            : "Some items failed to delete",
          { type: allSuccessful ? "success" : "error" }
        );

        if (allSuccessful) {
          setVisible(false);
          props.deselectItems();
          navigate("/equipment");
        }
      } else {
        // Handle single deletion
        const result = await deleteEquipment(equipment._id).unwrap();

        if (result.status === "success") {
          toast.success(result.message || "Equipment deleted successfully");
          setVisible(false);
          props.deselectItems();
          navigate("/equipment");
        } else {
          toast.error(result.message || "Failed to delete equipment");
        }
      }
    } catch (error) {
      console.error("Delete equipment error:", error);
      toast.error("Failed to delete equipment");
    }
  }

  return (
    <Modal
      loading={isDeleting}
      confirm={handleDelete}
      disabled={cannotDeleteEquipment()}
      visible={visible}
      setVisible={setVisible}
      title={title}
      content={renderContent()}
      footerPosition="right"
      contentWrapperclassName="p-3 lg:p-6 space-y-6"
    />
  );
};
