import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import { renderAmount, renderTotalMileage } from "../../../../../utils";

// Config for table and form
const tableConfig = [
  {
    Header: "Jobs",
    id: "jobs",
    cell: ({ getValue }) => getValue().length, //`${getValue()?.toLocaleString("en-US")} MI`,
    minSize: 120,
  },
  {
    Header: "Parts Total",
    id: "totals.partsTotal",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 100,
  },
  {
    Header: "Labor Total",
    id: "totals.hourlyTotal",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 100,
  },
  {
    Header: "Service Fees",
    id: "totals.serviceFeeTotal",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 100,
  },
  {
    Header: "Sales Tax",
    id: "totals.salesTax",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 100,
  },
  {
    Header: "Total",
    id: "calculatedTotalCharges",
    cell: ({ getValue }) => renderAmount(getValue()),
    size: 120,
  },
];

export const TOTAL_COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
