import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const inventoryApi = api.injectEndpoints({
  tagTypes: ["Inventory"],
  endpoints: (builder) => ({
    getInventoryItems: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          page,
          limit,
          ...rest,
        });
        return `inventory?${query}`;
      },
      providesTags: (result) =>
        result?.inventoryItems
          ? [
              ...result.inventoryItems.map(({ id }) => ({
                type: "Inventory",
                id,
              })),
              { type: "Inventory", id: "LIST" },
              { type: "Inventory", id: "all" },
            ]
          : [
              { type: "Inventory", id: "LIST" },
              { type: "Inventory", id: "all" },
            ],
    }),
    getInventoryItem: builder.query({
      query: ({ id, ...rest }) => `inventory/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => [
        { type: "Inventory", id: data.id },
      ],
    }),
    addInventoryItem: builder.mutation({
      query(body) {
        return {
          url: "inventory",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        { type: "Inventory", id: "LIST" },
        { type: "Inventory", id: "all" },
      ],
    }),
    // ... existing code ...
    getInventoryByCategory: builder.query({
      query: ({ category, equipment }) => {
        const params = getSearchParams({
          category,
          manufacturer: equipment?.manufacturer,
          model: equipment?.model,
          serialNumber: equipment?.serialNumber,
        });
        return `inventory/search?${params}`;
      },
      transformResponse: (response) => response?.data,
      providesTags: (result, error, arg) => [
        ...(result ? result.map(({ id }) => ({ type: "Inventory", id })) : []),
        { type: "Inventory", id: "LIST" },
        { type: "Inventory", id: arg.category || "all" },
      ],
    }),
    updateInventoryItem: builder.mutation({
      query({ id, data }) {
        return {
          url: `/inventory/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [{ type: "Inventory", id }],
    }),
    deleteInventoryItem: builder.mutation({
      query(id) {
        return {
          url: `/inventory/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, id) => [{ type: "Inventory", id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInventoryItemsQuery,
  useGetInventoryItemQuery,
  useGetInventoryByCategoryQuery,
  useAddInventoryItemMutation,
  useUpdateInventoryItemMutation,
  useDeleteInventoryItemMutation,
} = inventoryApi;

export default inventoryApi;
