export const EquipmentCard = ({ equipment }) => {
  return (
    <div className="mt-2 p-4 border rounded-lg shadow-sm bg-white">
      <h4 className="text-md font-semibold mb-2">Equipment Details</h4>
      <div className="flex flex-wrap">
        <p className="mr-4">
          <span className="font-medium">Make:</span>{" "}
          {equipment?.manufacturer || "N/A"}
        </p>
        <p className="mr-4">
          <span className="font-medium">Model:</span>{" "}
          {equipment?.model || "N/A"}
        </p>
        <p>
          <span className="font-medium">Serial Number:</span>{" "}
          {equipment?.serialNumber || "N/A"}
        </p>
      </div>
    </div>
  );
};
