import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import { capitalizeAll, formatDate, renderAmount } from "../../../../../utils";

// Config for table and form
const tableConfig = [
  {
    id: "appointmentDate",
    header: "Appointment Date",
    cell: ({ getValue }) => formatDate(getValue()),
    size: 120,
    columns: [],
  },
  {
    id: "jobId",
    header: "Work Order",
    minSize: 120,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: "hourlyTotal",
    header: "Labor Total",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 120,
  },
  // {
  //   id: "assignedTo",
  //   minSize: 100,
  //   cell: ({ getValue }) => {
  //     return (
  //       getValue()?.[0]?.user?.fullName || getValue()?.[0]?.fullName || "-"
  //     );
  //   },
  // },
  // {
  //   id: "summary",
  //   header: "Work Summary",
  //   minSize: 200,
  //   cell: ({ getValue }) => getValue() || "-",
  // },
  // {
  //   id: "hoursWorked",
  //   header: "Labor Hours",
  //   minSize: 110,
  //   cell: ({ getValue }) => {
  //     const value = getValue();
  //     if (!value) return "";
  //     return `${value} hrs`;
  //   },
  // },

  {
    id: "partsTotal",
    header: "Parts Total",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 110,
  },
  {
    id: "serviceFeeTotal",
    header: "Service Fee",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 110,
  },
  {
    id: "salesTax",
    header: "Sales Tax",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 110,
  },
  {
    id: "calculatedTotalCharges",
    header: "Total",
    cell: ({ getValue }) => renderAmount(getValue()),
    minSize: 130,
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
