export const loadTypeOptions = [
  {
    label: "Regular",
    value: "Regular",
    description: "This rate is the default drop charge.",
    id: "regular",
    type: "normal",
    effects: "dropCharges",
  },
  {
    label: "Hot",
    value: "Hot",
    description:
      "This rate will be added to Other Charges with the regular rate for drop charges.",
    id: "hot",
    type: "flat",
    effects: "baseCharges",
  },
  {
    label: "Layover",
    value: "Layover",
    description:
      "This is a flat rate that will override any base charge calculations.",
    id: "layover",
    base: true,
  },
  {
    label: "Deadhead",
    value: "Deadhead",
    description:
      "This is a flat rate that will override any base charge calculations.",
    id: "deadhead",
    base: true,
  },
  {
    label: "Reroute",
    value: "Reroute",
    description:
      "This rate will be added to Other Charges with the regular rate for drop charges.",
    id: "reroute",
  },
  {
    label: "Return",
    value: "Return",
    description:
      "This rate will be added to Other Charges with the regular rate for drop charges.",
    id: "return",
  },
  {
    label: "Internet",
    value: "Internet",
    description: "This rate affects the Drop Charge",
    id: "internet",
  },
  {
    label: "Backhaul",
    value: "Backhaul",
    description: "This rate affects the Drop Charge",
    id: "backhaul",
  },
  {
    label: "Empty",
    value: "Empty",
    description: "This rate affects the Drop Charge",
    id: "empty",
  },
];

export const multiplierRates = [
  {
    id: "baseRate",
    label: "Base Rate",
    value: "Base Rate",
    description: "This rate multiplies the mileage for Base Charges",
  },
  {
    id: "fuelRate",
    label: "Fuel Rate",
    value: "Fuel Rate",
    description: "This rate multiplies the mileage for Fuel Charges",
  },
  {
    id: "flatRate",
    label: "Flat Rate",
    value: "Flat Rate",
    description:
      "This rate overrides the mileages multipliers and replaces Base Charges",
  },
  {
    id: "standBy",
    label: "Stand By",
    value: "Stand By",
    description:
      "This rate multiplies the remaining wait time after the wait offset.",
  },
];

export const serviceRepairRates = [
  {
    id: "hourly",
    label: "Hourly",
    value: "Hourly",
    description: "Sets the standard hourly rate for all jobs.",
  },
  {
    id: "serviceFee",
    label: "Service Fee",
    value: "Service Fee",
    description: "Sets the base service fee for all jobs.",
  },
  {
    id: "partsMarkup",
    label: "Parts Markup",
    value: "Parts Markup",
    description: "Sets the mark up fee of all parts in percentage format.",
    type: "percent",
  },
  {
    id: "salesTaxRate",
    label: "Sales Tax",
    value: "Sales Tax",
    description: "Sets the sales tax rate for all jobs.",
    type: "percent",
  },
  {
    id: "employeeHourlyRate",
    label: "Employee Hourly Rate",
    value: "Employee Hourly Rate",
    description: "Sets the hourly rate for all employees.",
    type: "number",
  },
];

export const ratesMap = {
  serviceRepair: serviceRepairRates,
  trucking: [...loadTypeOptions, ...multiplierRates],
};
