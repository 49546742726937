import Card from "../../../shared/Card";
import EditableText from "../../../shared/EditableText";
import { parseFixed, renderAmount } from "../../../utils";

/**
 * PayoutTotals Component - Displays various totals for payouts
 * @param {Object} info - Contains all the total values
 * @param {Function} handleUpdate - Handler for updating total values
 * @param {string} appType - Type of application to filter totals
 */
const PayoutTotals = ({ info, handleUpdate, appType }) => {
  // Define which totals to show for each appType
  const totalConfig = {
    trucking: [
      "total",
      "totalMiles",
      "mileageTotal",
      "supplementalTotal",
      "hours",
      "hourlyTotal",
    ],
    default: ["total", "totalMiles", "mileageTotal"],
    serviceRepair: ["total", "hours", "hourlyTotal", "supplementalTotal"],
  };

  // Get the appropriate totals to display based on appType
  const visibleTotals = totalConfig[appType] || totalConfig.default;

  // Helper function to render total item
  const renderTotalItem = (key) => {
    const totalsMap = {
      total: (
        <div className="flex flex-col" key="total">
          <span className="text-gray-500 mb-1 font-semibold">Total</span>
          <span className="font-semibold text-lg">
            <p
              type="text"
              id="total"
              displayText={(e) => (
                <span className="font-semibold text-lg">{e}</span>
              )}
              value={info.total}
              onSave={handleUpdate}
            >
              {renderAmount(Number(info.total))}
            </p>
          </span>
        </div>
      ),
      totalMiles: (
        <div className="flex flex-col" key="totalMiles">
          <span className="text-gray-500 mb-1 font-semibold">Total Miles</span>
          <span className="font-semibold text-lg">
            <p>
              {parseFixed(info.totalMileage || 0).toLocaleString("en-US")} mi
            </p>
          </span>
        </div>
      ),
      mileageTotal: (
        <div className="flex flex-col" key="mileageTotal">
          <span className="text-gray-500 mb-1 font-semibold">
            Mileage Total
          </span>
          <span className="font-semibold text-lg">
            <p
              type="number"
              id="mileageTotal"
              displayText={(e) => (
                <span className="font-semibold text-lg">{e}</span>
              )}
              value={info.mileageTotal}
              onSave={handleUpdate}
            >
              {renderAmount(info.mileageTotal)}
            </p>
          </span>
        </div>
      ),
      supplementalTotal: (
        <div className="flex flex-col" key="supplementalTotal">
          <span className="text-gray-500 mb-1 font-semibold">
            Supplemental Total
          </span>
          <span className="font-semibold text-lg">
            <p
              type="number"
              id="supplementalTotal"
              displayText={(e) => (
                <span className="font-semibold text-lg">{e}</span>
              )}
              value={info.supplementalTotal}
              onSave={handleUpdate}
            >
              {renderAmount(info.supplementalTotal)}
            </p>
          </span>
        </div>
      ),
      hours: (
        <div className="flex flex-col" key="hours">
          <span className="text-gray-500 mb-1 font-semibold">Total Hours</span>
          <span className="font-semibold text-lg">
            <p
              type="number"
              id="hours"
              displayText={(e) => (
                <span className="font-semibold text-lg">{e}</span>
              )}
              value={info.hours}
              onSave={handleUpdate}
            >
              {parseFixed(info.hours)}
            </p>
          </span>
        </div>
      ),
      hourlyTotal: (
        <div className="flex flex-col" key="hourlyTotal">
          <span className="text-gray-500 mb-1 font-semibold">Hourly Total</span>
          <span className="font-semibold text-lg">
            <p
              type="number"
              id="hourlyTotal"
              displayText={(e) => (
                <span className="font-semibold text-lg">{e}</span>
              )}
              value={info.hourlyTotal}
              onSave={handleUpdate}
            >
              {renderAmount(info.hourlyTotal)}
            </p>
          </span>
        </div>
      ),
    };

    return totalsMap[key];
  };

  return (
    <Card className="row-span-2 px-5 py-4">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:grid-cols-4 xl:grid-cols-7">
        {visibleTotals.map((totalKey) => renderTotalItem(totalKey))}
      </div>
    </Card>
  );
};

export default PayoutTotals;
