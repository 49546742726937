import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils";

const CrumbsContext = createContext();

export const CrumbsProvider = ({ children }) => {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState([]);
  const [crumbsVisible, setCrumbsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rightContent, setRightContent] = useState(null);

  useEffect(() => {
    const newCrumbs = renderBreadcrumbs(location.pathname);
    setCrumbs(newCrumbs);
  }, [location.pathname]);

  const updateCrumbName = useCallback((data) => {
    const currentCrumbs = renderBreadcrumbs(location.pathname);
    const crumbIndex = currentCrumbs.findIndex(
      (crumb) => crumb.path === data.path
    );
    const updateCrumbs = [...currentCrumbs];
    updateCrumbs[crumbIndex] = {
      ...updateCrumbs[crumbIndex],
      ...data,
    };
    setCrumbs(updateCrumbs);
  }, []);

  const addCrumb = useCallback((data) => {
    setCrumbs((prevCrumbs) => [...prevCrumbs, data]);
  }, []);

  const value = useMemo(
    () => ({
      crumbsVisible,
      setCrumbsVisible,
      crumbs,
      setCrumbs,
      updateCrumbName,
      loading,
      setLoading,
      rightContent,
      setRightContent,
      addCrumb,
    }),
    [
      crumbsVisible,
      setCrumbsVisible,
      crumbs,
      setCrumbs,
      updateCrumbName,
      loading,
      setLoading,
      rightContent,
      setRightContent,
      addCrumb,
    ]
  );

  return (
    <CrumbsContext.Provider value={value}>{children}</CrumbsContext.Provider>
  );
};

export const useCrumbs = () => {
  return useContext(CrumbsContext);
};

function isEmptyOrSpaces(str) {
  return str === null || str.match(/^ *$/) !== null;
}

export function renderBreadcrumbs(str) {
  const data = [
    {
      path: "/",
      name: "Home",
    },
  ];

  if (str === "/") {
    return data;
  }

  const arr = str.split("/");

  let parentPath = "";

  for (let i = 0; i < arr.length; i++) {
    const key = arr[i];
    if (isEmptyOrSpaces(key)) {
      continue;
    }
    const path = parentPath + `/${key}`;
    data.push({
      path,
      name: capitalizeFirstLetter(key),
    });
    parentPath = path;
  }

  return data;
}
