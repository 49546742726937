import React from "react";
import { useAddInventoryItemMutation } from "../../redux/services/inventory/inventoryApi";
import { Modal, Button, Input, Select } from "../../shared";
import { partCategoryOptions } from "../../constants/categories";
import useForm from "../../hooks/useForm";

const CreateModal = ({ modalOpen, setModalOpen, onSuccess, onError }) => {
  const { form, updateForm, isValid } = useForm({
    validations: {
      name: { required: true },
      quantity: { required: true },
      unitPrice: { required: true },
      category: { required: true },
    },
  });

  const [addInventoryItem, { isLoading }] = useAddInventoryItemMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await addInventoryItem(form).unwrap();
      onSuccess(res.message);
      setModalOpen(false);
    } catch (error) {
      onError(error.message);
    }
  };

  const handleSelectChange = (selectedValue, fieldName) => {
    if (selectedValue) {
      const { fullValue, label } = selectedValue;
      updateForm({ id: fieldName, value: fullValue });
    }
  };

  return (
    <Modal
      visible={modalOpen}
      setVisible={setModalOpen}
      title="Create Inventory Item"
      footer={
        <div>
          <Button onClick={() => setModalOpen(false)} type="alternative">
            Cancel
          </Button>
          <Button
            loading={isLoading}
            onClick={handleSubmit}
            type="primary"
            disabled={!isValid}
          >
            Submit
          </Button>
        </div>
      }
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        <Select
          id="category"
          label="Category"
          value={form.category}
          onChange={(selectedValue) =>
            handleSelectChange(selectedValue, "category")
          }
          options={partCategoryOptions.map((category) => ({
            value: category.label,
            label: category.label,
          }))}
          isSubDropdown={true}
          placeholder="Select a category"
        />
        <Input
          id="name"
          label="Item Name"
          value={form.name}
          onChange={updateForm}
          required
        />
        <Input
          id="partNumber"
          label="Part Number"
          value={form.partNumber}
          onChange={updateForm}
        />
        <Input
          id="description"
          label="Description"
          value={form.description}
          onChange={updateForm}
          type="textarea"
        />
        <Input
          id="quantity"
          label="Quantity"
          type="number"
          value={form.quantity}
          onChange={updateForm}
          required
          min="0"
        />
        <Input
          id="unitPrice"
          label="Unit Price"
          type="number"
          value={form.unitPrice}
          onChange={updateForm}
          required
          min="0"
          step="0.01"
        />
        <Input
          id="supplier"
          label="Supplier"
          value={form.supplier}
          onChange={updateForm}
        />
      </form>
    </Modal>
  );
};

export default CreateModal;
