import control from "../../assets/control.png";
import logo from "../../assets/logos/logo-green-back.png";
import logolg from "../../assets/logos/logo-green-side-truck.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LayoutContext } from "../../layouts/Root";
import classNames from "classnames";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import Spinner from "../Spinner";

const borderStyle = "rounded-lg border border-primary-500 border-[1.3px]";

export const Sidebar = (props) => {
  const navigate = useNavigate();
  const { setNavOpen, navOpen } = useContext(LayoutContext);

  const wrapperClasses = classNames(
    props.className,
    "bg-white border-r-[1px] h-full p-5",
    "fixed duration-300",
    { "w-60": navOpen },
    { "w-20": !navOpen }
  );

  function renderItem({ isActive }, menuItem) {
    const Icon = menuItem.icon && menuItem.icon;

    const wrapClasses = classNames(
      "flex items-center my-1 h-10 text-gray-600 text-sm",
      {
        [borderStyle + " bg-primary-50 "]: isActive,
        "justify-center": !navOpen,
        "px-2": navOpen,
      }
    );
    const iconClasses = classNames("stroke-[1.7px]", {
      "stroke-primary-700": isActive,
      "w-5": navOpen,
      "w-6": !navOpen,
    });
    const textClasses = classNames(
      "origin-left transition duration-200 ease-in-out",
      {
        hidden: !navOpen,
        "mx-2": navOpen,
        "text-primary-700": isActive,
      }
    );

    return (
      <div className={wrapClasses}>
        {menuItem.src ? (
          <img src={menuItem.src} alt={`menu item img ${menuItem.title}`} />
        ) : (
          <Icon className={iconClasses} />
        )}
        <span className={textClasses}>{menuItem.title}</span>
      </div>
    );
  }

  return (
    <div className={wrapperClasses}>
      <div
        src={control}
        alt="toggle control"
        className={`absolute cursor-pointer bg-white -right-3 top-8 w-7 !border-[1.6px]
             ${borderStyle}  ${!navOpen && "rotate-180"} !rounded-full`}
        onClick={() => setNavOpen(!navOpen)}
      >
        <ChevronLeftIcon className="stroke-primary-600 stroke-1 fill-primary-600" />
      </div>
      <div
        className="flex gap-x-4 items-center cursor-pointer h-10"
        onClick={() => navigate("/")}
      >
        <img
          src={logo}
          alt="logo"
          className={`cursor-pointer duration-500 w-10 h-10 ${
            navOpen && "rotate-[360deg] opacity-0 !w-0"
          }`}
        />

        <img
          src={logolg}
          alt="logo"
          className={`cursor-pointer duration-500 w-40 ${
            !navOpen && "opacity-0 w-0"
          }`}
        />
      </div>
      <ul className="pt-6">
        {props.loading ? (
          <Spinner />
        ) : (
          props.menu.map((menuItem, index) => {
            return (
              <li key={index}>
                <NavLink to={menuItem.path}>
                  {(e) => renderItem(e, menuItem)}
                </NavLink>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
