import { api } from "../../api";
import { getSearchParams } from "../../../utils";

const shiftApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getShifts: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `shifts?${query}`;
      },
      providesTags: (data) =>
        data?.shifts
          ? [
              ...data.shift.map(({ _id }) => ({ type: "Shift", id: _id })),
              { type: "Shift", id: "LIST" },
            ]
          : [{ type: "Shift", id: "LIST" }],
    }),
    addShift: builder.mutation({
      query(body) {
        return {
          url: "shifts",
          method: "POST",
          body,
        };
      },
      transformResponse: (response) => response.data,
      invalidatesTags: [{ type: "Shift", id: "LIST" }],
      providesTags: (result) => {
        return [{ type: "Shift", id: "LIST" }];
      },
    }),
    getShift: builder.mutation({
      query: ({ id, ...rest }) => `shifts/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
    }),
    updateShift: builder.mutation({
      query({ id, data }) {
        return {
          url: `/shifts/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: "Shift", id }];
      },
    }),
    updateBreak: builder.mutation({
      query: ({ id, data }) => ({
        url: `shifts/${id}/break`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: "Shift", id }];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetShiftsQuery,
  useAddShiftMutation,
  useGetShiftMutation,
  useUpdateShiftMutation,
  useUpdateBreakMutation,
} = shiftApis;
