import { useState } from "react";
import { FolderPlusIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { SettingsPanel, Button, Avatar, Spinner } from "../../shared";
import UserAddModal from "./UserAddModal";
import { capitalizeFirstLetter } from "../../utils";
import UserExistsModal from "./UserExistsModal";
import UserActivateModal from "./UserActivateModal";
import UserDeleteModal from "./UserDeleteModal";
import useForm from "../../hooks/useForm";
import { Link } from "react-router-dom";
import {
  useUpdateUserMutation,
  useAddUserMutation,
  useDeleteUserMutation,
} from "../../redux/services/users/usersApi";
import { toast } from "react-toastify";
import { sendEmail } from "../../redux/reducers/users/userSlice";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { TrashIcon } from "@heroicons/react/24/outline";
import Dropdown from "../../shared/Dropdown";
import ButtonDropdown from "../../shared/ButtonDropdown";
import { isAuthorized } from "../../utils/authority";

const UsersSettingsLayout = ({
  data = {},
  title = "Users",
  subtitle,
  deactivate,
  addData,
  icon = (
    <FolderPlusIcon className="w-12 h-12 mx-auto text-gray-400 stroke-1" />
  ),
  ...props
}) => {
  const { user } = useAuth();
  const formHook = useForm();
  const dispatch = useDispatch();

  const [addUser, { isLoading: addingUser }] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [existsModal, setExistsModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activateModalType, setActivateModalType] = useState("deactivate");
  const [itemToActivate, setItemToActivate] = useState("");
  const [itemToDelete, setItemToDelete] = useState("");
  const [loading, setLoading] = useState(false);

  const dataKeys = Object.keys(data);
  const dataEmpty = dataKeys.length > 0;
  const isLoading = loading || props.loading;
  const emptyData = {
    content: isLoading ? (
      <div className="p-5">
        <Spinner />
      </div>
    ) : (
      <div className="py-5">
        <div className="text-center">
          {icon}
          <h3 className="mt-2 font-semibold text-sm">No Users found</h3>
          <p className="text-sm text-gray-500">
            Please try a different search term
          </p>
          {/* <div className="mt-3">{renderButton("xs", "Invite User")}</div> */}
        </div>
      </div>
    ),
  };

  const dataMap = data.users || [];

  function renderButton(size, content) {
    const wh = size === "md" ? 5 : 4;
    return (
      <Button
        onClick={() => setAddModalVisible(true)}
        size={size || "xs"}
        icon={<EnvelopeIcon className={`w-${wh} h-${wh} mr-2`} />}
      >
        {content || "Add data"}
      </Button>
    );
  }

  async function email(userId, type, options) {
    const email = await dispatch(
      sendEmail({ userId, type, ...options })
    ).unwrap();

    toast(email.message, {
      type: email.status,
      toastId: "user-email-sent-" + userId,
    });
  }

  function handleActivateModal(key, modalType) {
    setActivateModalType(modalType);
    setActivateModal(true);
    setItemToActivate(key);
  }

  function handleActivate() {
    if (activateModalType === "activate") {
      email(itemToActivate, "activate", {
        body: "You are receiving this email to reactivate your account.",
      });
    } else {
      const data = { _id: itemToActivate, active: false };
      updateUser(data);
    }
    closeActivateModal();
  }

  function closeActivateModal() {
    setLoading(false);
    setActivateModal(false);
    setItemToActivate("");
  }

  async function handleClickAddUser() {
    const role = formHook.form.role.toLowerCase();
    const body = { ...formHook.form, role, type: role };
    const res = await addUser(body).unwrap();

    toast(res.message, {
      toastId: "settings-add-user",
      type: res.status,
    });

    setAddModalVisible(false);
    formHook.clearForm();
  }

  function handleAddRate() {
    setLoading(true);
    addData({ ...formHook.form, configType: "rates" });
    setAddModalVisible(false);
    setExistsModal(false);
    formHook.clearForm();
    setLoading(false);
  }

  function handleDeleteUserModal(userId) {
    setDeleteModal(true);
    setItemToDelete(userId);
  }

  async function handleDeleteUser() {
    const res = await deleteUser(itemToDelete).unwrap();

    toast(res.message, {
      toastId: "settings-delete-user",
      type: res.status,
    });

    if (res.status === "success") {
      setDeleteModal(false);
    }
  }

  function itemLayout(item, i) {
    const isOwner = item.accountHolder;
    const isSelf = item.email === user.email;

    const actions = [
      {
        id: "activate",
        title: item.active ? "Deactivate" : "Send Activation Link",
        remove: isSelf || !isAuthorized("owner, admin"),
        onClick: () =>
          item.active
            ? handleActivateModal(item._id, "deactivate")
            : handleActivateModal(item._id, "activate"),
      },
      {
        id: "delete",
        remove: !isAuthorized("owner, admin"),
        title: "Delete",
        onClick: () => handleDeleteUserModal(item._id),
      },
    ].filter((i) => {
      if (i.remove) {
        return false;
      }
      return true;
    });
    const isLoading = loading || props.loading;
    return (
      <div
        key={i}
        className="grid grid-cols-8 items-center p-3 bg-white rounded-b-xl"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex items-center col-span-4">
              <Avatar size="sm" src={item.avatar} />
              <div className="ml-3">
                <Link
                  to={`/settings/users/${item._id}`}
                  className="text-lg hover:text-primary-700 text-primary-600 cursor-pointer"
                >
                  {item.fullName}
                </Link>
                <div className="text-sm text-gray-500">{item.email}</div>
              </div>
            </div>
            <div className="col-span-2 text-gray-800">
              {isOwner
                ? "Account Holder"
                : capitalizeFirstLetter(item.role || item.type)}
            </div>
            <div className="col-start-8 flex justify-end items-center space-x-2">
              <ButtonDropdown size="xs" type="light" data={actions}>
                Actions
              </ButtonDropdown>
              {/* <div
                className="text-xs text-red-500 cursor-pointer"
                onClick={() => handleDeleteUserModal(item._id)}
              >
                <TrashIcon className="w-5 stroke-red-500" />
              </div> */}
              {/* <Button
                disabled={isSelf}
                type="alternative"
                onClick={() =>
                  item.active
                    ? handleActivateModal(item._id, "deactivate")
                    : handleActivateModal(item._id, "activate")
                }
                style={{ minWidth: 170 }}
              >
                {item.active ? "Deactivate" : "Send Activation Link"}
              </Button> */}
            </div>
          </>
        )}
      </div>
    );
  }

  const actions = renderButton("xs", "Invite User");

  const isEmpty = dataMap.length === 0;
  const settingData = isEmpty ? [] : dataMap;

  return (
    <>
      <div className="space-y-4">
        <SettingsPanel
          itemLayout={itemLayout}
          title={title}
          empty={emptyData}
          subtitle={subtitle}
          data={settingData}
          actions={actions}
        />
      </div>
      <UserAddModal
        form={formHook}
        visible={addModalVisible}
        setVisible={setAddModalVisible}
        submit={handleClickAddUser}
        loading={loading}
      />
      <UserExistsModal
        visible={existsModal}
        setVisible={setExistsModal}
        submit={handleAddRate}
        loading={loading}
      />
      <UserActivateModal
        visible={activateModal}
        setVisible={setActivateModal}
        submit={handleActivate}
        loading={loading}
        type={activateModalType}
      />
      <UserDeleteModal
        visible={deleteModal}
        setVisible={setDeleteModal}
        submit={handleDeleteUser}
        loading={loading}
      />
    </>
  );
};

export default UsersSettingsLayout;
