import React from "react";
import { jsPDF } from "jspdf";
import { generateInvoiceCoverPage, generateJobPage } from "./utils.invoice";
import { Button } from "../../../shared";
import { useConfig } from "../../../hooks";
import { format } from "date-fns";

const InvoiceGeneratorButton = ({
  invoice,
  button: ButtonComponent,
  filename = null,
  download = true,
  previewId = "pdf-preview",
  ...props
}) => {
  const { appType } = useConfig();

  const generatePDF = async () => {
    const doc = new jsPDF();

    // Generate cover page
    generateInvoiceCoverPage(doc, invoice, appType);

    // Generate job pages
    for (const job of invoice.jobs || []) {
      doc.addPage();
      generateJobPage(doc, {
        ...job,
        client: invoice.client,
        parentCompany: invoice.parentCompany,
      });
    }

    // Generate default filename if none provided
    const defaultFilename =
      invoice.invoiceId + "_" + format(new Date(), "M-d-yy");
    const finalFilename = filename || defaultFilename;

    if (download) {
      doc.save(finalFilename);
      return;
    }

    // Display PDF in iframe
    const pdfOutput = doc.output("datauristring");
    document.getElementById(previewId).src = pdfOutput;
  };

  // If a custom button component is provided, clone it with the onClick handler
  if (ButtonComponent) {
    return React.cloneElement(ButtonComponent, {
      onClick: generatePDF,
    });
  }

  // Default button if no custom button is provided
  return (
    <Button {...props} onClick={generatePDF}>
      Generate PDF
    </Button>
  );
};

export default InvoiceGeneratorButton;
