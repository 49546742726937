import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useAuth } from "./useAuth";
import { isObject } from "../utils";
import { toast } from "react-toastify";
import { APP_TYPE, PARENT_COMPANY } from "../config/defaultSettings";
import {
  useFetchConfigMutation,
  useUpdateConfigMutation,
  useDeleteConfigMutation,
} from "../redux/services/config/configApi";

const contextDefaultValues = {
  configs: { parentCompany: {} },
  addConfig: () => Promise.resolve(),
  deleteConfig: () => Promise.resolve(),
  appType: "",
  parentCompany: "",
  loading: true,
  cleanUpConfig: () => {},
};

export const ConfigContext = createContext(contextDefaultValues);

export const ConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState({});
  const { user, initialized, isAuthenticated } = useAuth();
  const [configInitialized, setConfigInitialized] = useState(false);
  const parentCompany = localStorage.getItem(PARENT_COMPANY);
  const appType = localStorage.getItem(APP_TYPE);

  const [fetchConfig, { isLoading: getConfigLoading, ...rest }] =
    useFetchConfigMutation();
  const [updateConfig, { isLoading: updateConfigLoading }] =
    useUpdateConfigMutation();
  const [deleteConfigItem, { isLoading: deleteConfigLoading }] =
    useDeleteConfigMutation();

  useEffect(() => {
    const fetchConfigs = async () => {
      if (!configInitialized && user?.active && parentCompany) {
        try {
          let configData = await fetchConfig({ parentCompany }).unwrap();
          const { config } = configData;

          localStorage.setItem(APP_TYPE, config.configs.parentCompany.appType);
          setConfigs(config ? config.configs : {});
        } catch (err) {
          console.error("[Config] Failed to fetch configurations:", err);
          toast.error(
            "Failed to fetch configurations. Please try again later."
          );
        } finally {
          setConfigInitialized(true);
        }
      }
    };

    if (initialized && isAuthenticated) {
      fetchConfigs();
    } else {
      cleanUpConfig();
    }
  }, [
    initialized,
    configInitialized,
    user?.active,
    parentCompany,
    isAuthenticated,
    fetchConfig,
  ]);

  const addConfig = useCallback(
    async (newConfigItem) => {
      if (!parentCompany) {
        return;
      }

      try {
        const response = await updateConfig({
          parentCompany,
          data: newConfigItem,
        }).unwrap();
        // const response = await fetch(`/config/${parentCompany}`, {
        //   method: "PUT",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(newConfigItem),
        //   credentials: "include",
        // });

        const data = response;

        if (data.status === "success") {
          setConfigs(data.config.configs);
          toast.success(data.message);
        }
      } catch (error) {
        console.error("[Config] Failed to add config:", error);
        toast.error("Failed to add configuration");
      }
    },
    [parentCompany]
  );

  const deleteConfig = useCallback(
    async (configData) => {
      if (!parentCompany) {
        console.warn("[Config] Delete config aborted - no parent company");
        return;
      }

      try {
        const response = await deleteConfigItem({
          parentCompany,
          data: configData,
        }).unwrap();
        // const response = await fetch(`/config/${parentCompany}`, {
        //   method: "DELETE",
        //   headers: {
        //   "Content-Type": "application/json",
        // },
        //   body: JSON.stringify(configData),
        //   credentials: "include",
        // });

        const data = response;

        if (data.status === "success") {
          setConfigs(data.config.configs);
          toast.success(data.message);
        }
      } catch (error) {
        console.error("[Config] Failed to delete config:", error);
        toast.error("Failed to delete configuration");
      }
    },
    [parentCompany]
  );

  const cleanUpConfig = useCallback(() => {
    setConfigs({});
    setConfigInitialized(false);
    localStorage.removeItem(APP_TYPE);
  }, []);

  const contextValue = useMemo(() => {
    return {
      configs: configs || {},
      addConfig,
      deleteConfig,
      parentCompany,
      appType: configs.parentCompany?.appType || appType,
      loading:
        !configInitialized ||
        getConfigLoading ||
        updateConfigLoading ||
        deleteConfigLoading,
      cleanUpConfig,
    };
  }, [
    configs,
    addConfig,
    deleteConfig,
    parentCompany,
    configInitialized,
    getConfigLoading,
    updateConfigLoading,
    deleteConfigLoading,
    cleanUpConfig,
  ]);

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

export default ConfigProvider;
