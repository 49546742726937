import { Link } from "react-router-dom";
import {
  renderCustomColumnDefs,
  renderValidations,
} from "../../../../shared/Table/table.utils";
import { formatDateTime, formatTimeWorked } from "../../../../utils";
import { Badge } from "../../../../shared";
import { capitalizeFirstLetter } from "../../../../utils";

const tableConfig = [
  {
    id: "startTime",
    header: "Start Time",
    visible: true,
    required: true,
    minWidth: 180,
    cell: ({ getValue }) => formatDateTime(getValue()),
  },
  {
    id: "endTime",
    header: "End Time",
    minWidth: 180,
    cell: ({ getValue }) => (getValue() ? formatDateTime(getValue()) : "-"),
  },
  {
    id: "status",
    header: "Status",
    minWidth: 140,
    cell: ({ getValue }) => (
      <Badge
        label={capitalizeFirstLetter(getValue())}
        variant={getStatusVariant(getValue())}
      />
    ),
  },
  {
    id: "totalTime",
    header: "Total Time",
    minWidth: 100,
    cell: ({ getValue }) => `${formatTimeWorked(getValue()).display}`,
  },
  {
    id: "overtime",
    header: "Overtime",
    minWidth: 100,
    cell: ({ getValue }) => (
      <Badge
        label={getValue() ? "Yes" : "No"}
        variant={getValue() ? "warning" : "default"}
      />
    ),
  },
  {
    id: "breaks",
    header: "Breaks",
    minWidth: 160,
    cell: ({ getValue }) => {
      const breaks = getValue();
      if (!breaks || breaks.length === 0) return "-";
      return breaks.map((breakItem, index) => (
        <Badge
          key={index}
          label={`${breakItem.type} ${breakItem.active ? "(Active)" : ""}`}
          className="mr-1 mb-1"
        />
      ));
    },
  },
  // {
  //   id: "location",
  //   header: "Location",
  //   minWidth: 200,
  //   cell: ({ getValue }) => {
  //     const location = getValue();
  //     if (!location?.coordinates) return "-";
  //     return `${location.coordinates[1]}, ${location.coordinates[0]}`;
  //   },
  // },
  // {
  //   id: "notes",
  //   header: "Notes",
  //   minWidth: 200,
  //   cell: ({ getValue }) => getValue() || "-",
  // },
];

// Helper function to determine badge variant based on status
const getStatusVariant = (status) => {
  switch (status) {
    case "in-progress":
      return "primary";
    case "completed":
      return "success";
    case "cancelled":
      return "danger";
    case "scheduled":
      return "warning";
    default:
      return "default";
  }
};

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
// export const FORM_OPTIONS = { validations: renderValidations(tableConfig) };
export const PAGE_SIZE_OPTIONS = [
  { label: "10 Shifts", value: 10 },
  { label: "20 Shifts", value: 20 },
  { label: "50 Shifts", value: 50 },
];
