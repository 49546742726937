import React from "react";
import { Link } from "react-router-dom";
import { HomeIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Spinner from "../Spinner";

export const Breadcrumbs = (props) => {
  function renderBreadcrumbs(arr) {
    return arr.map((item, i) => {
      const icon =
        item.path === "/" ? (
          <HomeIcon className="w-4 h-4 mr-2" />
        ) : (
          <ChevronRightIcon className="w-4 h-4 mr-2" />
        );

      const isLoading = i + 1 === arr.length && props.loading;

      return (
        <li
          key={i}
          className="cursor-default inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
        >
          {/* {icon}
          {isLoading ? <Spinner size="sm" /> : item.name} */}
          <Link
            to={item.path}
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900"
          >
            {icon}
            {isLoading ? <Spinner size="sm" /> : item.name}
          </Link>
        </li>
      );
    });
  }

  return (
    <nav
      className="flex my-2 mx-5 whitespace-nowrap items-center justify-between scrollbar-hide"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {renderBreadcrumbs(props.data)}
      </ol>
      <div className="flex items-center justify-center">
        {props.rightContent}
      </div>
    </nav>
  );
};

export default Breadcrumbs;
