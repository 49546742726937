import { useGetTemplatesQuery } from "../../redux/services/templates/api";
import { Select } from "../../shared";
import { useCallback } from "react";

const TemplateSelect = ({ type, onChange, value }) => {
  const {
    data: templateData,
    isLoading,
    isError,
  } = useGetTemplatesQuery({
    type,
  });

  const emptyData = [{ label: "No templates found", value: null }];
  const templates =
    templateData?.templates?.map((template) => ({
      label: template.name,
      value: template._id,
    })) || [];

  const handleChange = useCallback(
    (selected) => {
      onChange(selected);
    },
    [onChange]
  );

  return (
    <Select
      value={value}
      loading={isLoading}
      wrapperClasses="w-3/5"
      options={templates.length === 0 ? emptyData : templates}
      size="sm"
      labelClasses="text-gray-700"
      onChange={handleChange}
      id="template"
      placeholder="Select Template"
    />
  );
};

export default TemplateSelect;
