import axios from "axios";
import { getAppType, getParentCompany } from "./authority";
import {
  ROOT_API_URL,
  ROOT_URL,
  PARENT_COMPANY,
} from "../config/defaultSettings";
import join from "join-path";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const clearAuthData = async () => {
  try {
    await axios.post(`${ROOT_API_URL}/account/logout`, null, {
      withCredentials: true,
    });
  } catch (error) {
    console.error("Error during logout:", error);
  }
  window.location.replace("/login");
};

// Create axios instance with defaults
const axiosInstance = axios.create({
  baseURL: ROOT_API_URL,
  withCredentials: true,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add common headers
    config.headers["x-client-type"] = "web";
    config.headers["x-app-type"] = getAppType();

    const parentCompany = getParentCompany();
    if (parentCompany && parentCompany !== "null") {
      config.headers["x-parent-company"] = parentCompany;
      config.headers["Customer-Context"] = parentCompany;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    try {
      // Version check
      const serverVersion = response.headers["x-version"];
      const currentVersion = import.meta.env.PACKAGE_VERSION;

      if (serverVersion && serverVersion > currentVersion) {
        window.localStorage.setItem("version-update-needed", "true");
      }
      window.localStorage.setItem("version", currentVersion);
    } catch (err) {
      console.error("Version Check Error:", err);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Handle 401/403 errors
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry
    ) {
      // Only try refresh if we get a TOKEN_EXPIRED error
      if (error.response?.data?.code === "TOKEN_EXPIRED") {
        if (!isRefreshing) {
          isRefreshing = true;
          originalRequest._retry = true;

          try {
            const refreshResult = await axiosInstance.post("/account/refresh");

            if (refreshResult.data?.status === "success") {
              processQueue(null, refreshResult.data);
              // Retry original request
              return axiosInstance(originalRequest);
            } else {
              processQueue(new Error("Token refresh failed"));
              await clearAuthData();
              return Promise.reject(error);
            }
          } catch (refreshError) {
            console.error("Error during token refresh:", refreshError);
            processQueue(refreshError);
            await clearAuthData();
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          // Wait for the refresh to complete
          try {
            await new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            });
            // Retry original request after refresh
            return axiosInstance(originalRequest);
          } catch (queueError) {
            console.error("Error processing queued request:", queueError);
            await clearAuthData();
            return Promise.reject(queueError);
          }
        }
      } else {
        // Only clear auth for non-TOKEN_EXPIRED errors
        await clearAuthData();
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

// Main request function
async function request(url, options) {
  const config = {
    ...options,
    url,
  };

  try {
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.error("Request failed:", {
      url,
      error: error.message,
    });
    throw error;
  }
}

export default request;

export function getSearchParams(obj) {
  return new URLSearchParams(obj);
}

/*
 * Legacy create search URLSearchParams
 * @deprecated
 */
export function renderQueries(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
}
