import React, { useEffect, useRef, useState } from "react";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNewPayout } from "../../../redux/reducers/payouts/payoutSlice";
// import { FORM_OPTIONS } from "../table.config/";
import Select from "../../../shared/Select";
import { fetchDrivers } from "../../../redux/reducers/drivers/driversSlice";
import { useAuth } from "../../../hooks/useAuth";
import FormLayout from "../../../layouts/FormLayout";
import { driverSelectOptions } from "../../../redux/reducers/drivers/driversSlice";
import LegsTable from "./LegsTable";
import Card from "../../../shared/Card";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  fetchLegs,
  resetLegs,
  setLegs,
} from "../../../redux/reducers/legs/legsSlice";
import SelectedLegsTable from "./SelectedLegsTable";
import { parseFixed, renderAmount } from "../../../utils";
import Input from "../../../shared/Input";
import Dropdown from "../../../shared/Dropdown";
import {
  fetchOrganizations,
  selectOrganizationsOptions,
} from "../../../redux/reducers/organizations/organizationsSlice";
import DriverTable from "./DriverTable";
import { DisplayTotal } from "../Edit";
import { SidebarContainer } from "../../../components";
import Tabs from "../../../shared/Tabs";

const PayoutCreate = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { form, updateForm, clearForm, setFormInit } = useForm();

  const driverStatus = useSelector((state) => state.driver.status);
  const driverOptions = useSelector(driverSelectOptions);

  const organizationStatus = useSelector((state) => state.organization.status);
  const organizationOptions = useSelector(selectOrganizationsOptions);

  const legs = useSelector((state) => state.legs.legs);
  const legsStatus = useSelector((state) => state.legs.status);

  const [buttonTitle, setButtonTitle] = useState("Submit");
  const [selectedLegs, setSelectedLegs] = useState([]);
  const [selectedPayoutItems, setSelectedPayoutItems] = useState([]);
  const [payoutItems, setPayoutItems] = useState([]);
  const [type, setType] = useState("Individual");
  const [selectedOrganizationDrivers, setSelectedOrganizationDrivers] =
    useState([]);

  useEffect(() => {
    if (driverStatus === "idle") {
      dispatch(fetchDrivers({ parent: user.parentCompany, type: "driver" }));
    }
  }, [driverStatus, dispatch, user]);

  useEffect(() => {
    if (organizationStatus === "idle") {
      dispatch(fetchOrganizations({ parent: user.parentCompany }));
    }
  }, [organizationStatus, dispatch, user]);

  useEffect(() => {
    if (form.organization && selectedOrganizationDrivers.length === 0) {
      setSelectedOrganizationDrivers(form.organization.employees);
    }
  }, [form.organization]);

  useEffect(() => {
    if ((form.driver || form.drivers) && legsStatus === "idle") {
      let query;

      if (
        type === "Organization" &&
        form.organization &&
        selectedOrganizationDrivers.length > 0
      ) {
        query = {
          parent: user.parentCompany,
          payoutId: false,
          route: true,
          customQuery: JSON.stringify({
            driver: {
              $in: selectedOrganizationDrivers.map((i) => i._id),
            },
          }),
        };
      } else {
        query = {
          parent: user.parentCompany,
          driverId: form.driver._id,
          payoutId: false,
          route: true,
        };
      }
      dispatch(fetchLegs(query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    legsStatus,
    form.type,
    form.organization,
    selectedOrganizationDrivers.length,
    dispatch,
    form.drivers,
    form.driver,
    user,
  ]);

  useEffect(() => {
    let payoutTotal = renderPayoutTotal();
    const payoutMileage = renderPayoutMileage();
    const payoutWaitTime = renderPayoutWaitTime();

    setFormInit({
      hours: payoutWaitTime,
      payoutMileage: payoutMileage,
      totalPayout: payoutTotal,
    });
  }, [
    payoutItems.length,
    form.totalPayout,
    form.payoutMileage,
    form.hours,
    form.rate,
    form.mileageRate,
  ]);

  useEffect(() => {
    return () => dispatch(resetLegs());
  }, [dispatch, form.driver, form.drivers, form.organization]);

  function addToPayout() {
    const newData = legs.filter((x) => !selectedLegs.includes(x));
    onFormChange({ id: "legs", value: [...payoutItems, ...selectedLegs] });
    dispatch(setLegs(newData));
    setPayoutItems([...payoutItems, ...selectedLegs]);
  }

  function removeFromPayout() {
    const newData = [...legs, ...selectedPayoutItems];
    const newpayoutItems = payoutItems.filter(
      (x) => !selectedPayoutItems.includes(x)
    );
    dispatch(setLegs(newData));
    setPayoutItems(newpayoutItems);
  }

  function onFormChange(e) {
    const { value, id } = e;
    updateForm({ value, id });
  }

  async function handleSubmit() {
    const data = {
      ...form,
      driver: type === "Individual" ? form.driver : selectedOrganizationDrivers,
      type: form.type,
      legs: payoutItems,
      status: "Review",
    };

    const res = await dispatch(
      addNewPayout({ data, parentId: user.parentCompany })
    ).unwrap();
    if (res.status === "success") {
      navigate(`/payouts/${res.data._id}`);
    }
  }

  function handleNext() {
    setButtonTitle("Submit");
    setFormInit({ drivers: selectedOrganizationDrivers });
  }

  const renderPayoutMileage = () => {
    let totalPayoutMileage = payoutItems
      .map((item) => {
        return item.route?.mileage || 0;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return totalPayoutMileage;
  };

  const renderPayoutWaitTime = () => {
    let total = payoutItems
      .map((item) => {
        return item.waitTime?.total || 0;
      })
      .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

    return form.hours || total;
  };

  const renderHourlyTotal = () => {
    let totalHourly = Number(form.rate) * Number(form.hours);
    return totalHourly;
  };

  const renderMileageTotal = () => {
    const res = Number(form.payoutMileage) * Number(form.mileageRate);
    return typeof res === "number" ? res : 0;
  };

  // const totalLegs = (
  //   <div className="flex items-center">
  //     # of Drops:{" "}
  //     <span className="font-bold pl-2 text-xl">{payoutItems.length}</span>
  //   </div>
  // );

  // const mileageTotals = (
  //   <div className="flex items-center">
  //     Mileage Total:{" "}
  //     <span className="font-bold pl-2 text-xl">
  //       {renderAmount(renderMileageTotal())}
  //     </span>
  //   </div>
  // );

  // const hourlyTotals = (
  //   <div className="flex items-center">
  //     Hourly Total:{" "}
  //     <span className="font-bold pl-2 text-xl">
  //       {renderAmount(renderHourlyTotal())}
  //     </span>
  //   </div>
  // );

  const totalCharges = (
    <div className="flex items-center">
      Payout Total:{" "}
      <span className="font-bold pl-2 text-xl">
        {renderAmount(form.totalPayout)}
      </span>
    </div>
  );

  const driver = form.driver && (
    <div className="flex items-center">
      Driver:{" "}
      <span className="font-bold text-xl ml-2 flex items-center">
        {form.driver.fullName}{" "}
        <XMarkIcon
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            clearForm("driver");
            setPayoutItems([]);
          }}
        />
      </span>
    </div>
  );

  const renderPayoutTotal = () => {
    const hourlyTotal = renderHourlyTotal() || 0;
    const mileageTotal = renderMileageTotal() || 0;
    let totalPayoutCharges = Number(hourlyTotal) + Number(mileageTotal);
    return totalPayoutCharges;
  };

  const headerRef = useRef();
  const [headerContext, setHeaderContext] = useState();
  useEffect(() => {
    setHeaderContext(headerRef.current);
  }, [headerRef.current]);

  const renderHeader = (headerProps) => {
    return (
      <div ref={headerRef} className="p-2 border-b">
        {/* {actions} */}
      </div>
    );
  };

  const legsSelector = (
    <div className="px-3 pb-20 overflow-y-auto">
      <div className="flex flex-col xl:flex-row justify-evenly justify-items-stretch space-x-0 xl:space-x-2 ">
        <div className="flex-grow ">
          <LegsTable
            data={legs}
            setData={setSelectedLegs}
            client={form.client}
          />
        </div>
        <div className="flex-grow-0 self-center z-50">
          <div className="hidden xl:block">
            <IconButton icon={ChevronRightIcon} onClick={addToPayout} />
            <IconButton icon={ChevronLeftIcon} onClick={removeFromPayout} />
          </div>
          <div className="flex xl:hidden">
            <IconButton icon={ChevronUpIcon} onClick={removeFromPayout} />
            <IconButton icon={ChevronDownIcon} onClick={addToPayout} />
          </div>
        </div>
        <div className="flex-grow">
          <SelectedLegsTable
            setSelectedItems={setSelectedPayoutItems}
            selectedData={payoutItems}
            client={form.client}
          />
        </div>
      </div>
      <Card className="grid grid-cols-2 md:grid-cols-4 gap-3 my-3">
        <Input
          onChange={onFormChange}
          id="hours"
          label="Standby hours"
          type="number"
          value={form.hours}
        />
        <Input
          onChange={onFormChange}
          id="rate"
          label="Hourly Rate"
          value={form.rate}
          type="number"
        />
        <Input
          onChange={onFormChange}
          id="mileageRate"
          label="Mileage Rate"
          type="number"
          value={form.mileageRate}
        />
      </Card>

      <DisplayTotal title="# of Drops" value={payoutItems.length} />
      <DisplayTotal title="Total Miles" value={form.payoutMileage + " mi"} />
      <DisplayTotal
        title="Mileage Total"
        value={renderAmount(renderMileageTotal())}
      />
      <DisplayTotal
        title="Hourly Total"
        value={renderAmount(renderHourlyTotal())}
      />
      <div>{totalCharges}</div>
    </div>
  );

  function handleTypeChange(e) {
    setType(e.value);
    onFormChange(e);
    clearForm();
    if (e.value === "Organization") {
      setButtonTitle("Next");
    } else {
      setButtonTitle("Submit");
    }
  }

  const formTypeOptions = [
    {
      value: "Individual",
      onClick: handleTypeChange,
      label: "Individual",
    },
    {
      value: "Organization",
      onClick: handleTypeChange,
      label: "Organization",
    },
  ];

  const formTypeTrigger = (
    <div className="flex items-center cursor-pointer font-semibold">
      {type}
      <ChevronDownIcon className="h-3 w-3 ml-1" />
    </div>
  );

  const actions = (
    <div>
      <Dropdown
        align="left"
        closeOnClick
        trigger={formTypeTrigger}
        data={formTypeOptions}
      />
    </div>
  );

  const tabs = [
    {
      id: "Create",
      content: (
        <div className=" w-full">
          <FormLayout
            contentClassname="px-3 pb-3"
            title={driver}
            handleSubmit={buttonTitle === "Next" ? handleNext : handleSubmit}
            actions={totalCharges}
            buttonTitle={buttonTitle}
            hideButtons={
              type === "Individual"
                ? !form.driver
                  ? true
                  : false
                : !form.organization
                ? true
                : false
            }
          >
            <div>{actions}</div>
            {type === "Organization" ? (
              <div className="my-5">
                {!form.organization ? (
                  <>
                    <Select
                      id="organization"
                      onChange={handleSelect}
                      label="Organization"
                      options={organizationOptions}
                      placeholder="Select an organization"
                    />
                  </>
                ) : !form.drivers ? (
                  <DriverTable
                    setSelectedItems={setSelectedOrganizationDrivers}
                    selectedData={form.organization.employees}
                  />
                ) : (
                  legsSelector
                )}
              </div>
            ) : (
              <div className="my-5">
                {!form.driver ? (
                  <>
                    <Select
                      id="driver"
                      onChange={handleSelect}
                      label="Driver"
                      options={driverOptions}
                      placeholder="Select a driver"
                    />
                  </>
                ) : (
                  legsSelector
                )}
              </div>
            )}
          </FormLayout>
        </div>
      ),
    },
  ];

  function handleSelect({ id, value }) {
    setFormInit({
      [id]: value,
      rate: Number(value.meta?.hourlyRate),
      mileageRate: Number(value.meta?.mileageRate),
    });
  }

  return (
    <SidebarContainer
      // title={title}
      hideSidebar
      header={renderHeader}
      // actions={actions}
      // sidebarContent={sidebarContent}
      // sidebarTitle={<h2 className="text-xl bg-white py-2">Communications</h2>}
    >
      {({ parentHeight, parentWidth }) => {
        const offsetTop = headerContext?.offsetTop;
        const offsetHeight = headerContext?.offsetHeight;
        const wrapperStyle = { height: `${parentHeight - offsetTop}px` };
        const panelStyle = {
          height: `${parentHeight - offsetTop - offsetHeight - 38}px`,
        };
        return (
          <div style={wrapperStyle} className="h-auto w-full">
            <Tabs
              className={`w-full overflow-hidden`}
              style={wrapperStyle}
              panelStyle={panelStyle}
              useSearchParams
              panelClasses={`border-t overflow-y-scroll`}
              tabListClasses="bg-white h-[38px]"
              tabclassName="mx-1"
              id="job-show"
              // onClick={handleTabClick}
              data={tabs}
            />
          </div>
        );
      }}
    </SidebarContainer>
  );
};

const IconButton = (props) => {
  const Icon = props.icon;
  return (
    <div className="flex flex-col items-center my-4 mx-1">
      <div
        onClick={props.onClick}
        className="border ease-in-out duration-300 hover:border-gray-300 rounded-full p-2 hover:bg-gray-200 cursor-pointer"
      >
        <Icon className="w-6 h-6" />
      </div>
      {props.label && <p className="font-semibold">{props.label}</p>}
    </div>
  );
};

export default PayoutCreate;
