import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import {
  deleteClients,
  resetClients,
} from "../../redux/reducers/clients/clientsSlice";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useDeleteClientMutation,
  useGetClientsQuery,
} from "../../redux/services/clients/clientsApi";
import { usePrefetch } from "../../redux/api";
import { CreateClientModal } from "./CreateModal";
import { useDebounceSearch } from "../../hooks";
import { removeEmptyValues } from "../../utils";

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const { search, handleSearch } = useDebounceSearch();
  const [filters, setFilters] = useState([]);
  const [deleteClients] = useDeleteClientMutation();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const searchFilters = filters.reduce((a, v) => {
    if (v.id === "transactionDate") {
      return {
        ...a,
        startDate: v.value?.start.toISOString(),
        endDate: v.value?.end.toISOString(),
      };
    }
    return { ...a, [v.id]: v.value };
  }, {});

  const { data, isLoading, isFetching } = useGetClientsQuery({
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    searchTerm: search.term,
    ...removeEmptyValues(searchFilters),
  });

  const prefetchPage = usePrefetch("getClients");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  function handleEdit(itemId) {
    navigate(`/clients/${itemId}`);
  }

  async function handleDelete(items) {
    try {
      // Map the items to extract their IDs
      const itemIds = items.map((item) => item._id);

      const res = await deleteClients({
        itemIds,
      }).unwrap();

      return res;
    } catch (error) {
      toast("Error deleting clients", {
        type: "error",
        toastId: "clientsDeleteError",
      });
      throw error;
    }
  }

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleSearch(value);
  }
  return (
    <>
      <TableLayout
        id="clients"
        loading={isLoading || isFetching}
        columns={COLUMN_DEFINITIONS}
        data={data?.clients}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        mainAction={{ onClick: () => setModalVisible(true) }}
        onPaginationChange={setPagination}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        handleDelete={handleDelete}
        // noDelete
        handleEdit={handleEdit}
        fullTextSearch={globalFilter}
      />
      <CreateClientModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  );
};

export default Clients;
