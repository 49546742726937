import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../shared/Button";
import Table from "../../../../shared/Table";
import { useAuth } from "../../../../hooks/useAuth";
import {
  useGetShiftsQuery,
  useUpdateShiftMutation,
} from "../../../../redux/services/shifts/shiftsApi";
import { COLUMN_DEFINITIONS } from "./table.config";

/**
 * EmployeeShifts component displays and manages shifts for a specific employee
 * @param {Object} props - Component props
 * @param {Object} props.employee - Employee data
 */
const EmployeeShifts = ({ employee }) => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  // RTK Query hooks
  const { data, isLoading, isSuccess } = useGetShiftsQuery({
    parent: user.parentCompany,
    userId: user._id,
    employee: params.id,
  });

  const [updateShift] = useUpdateShiftMutation();

  async function handleUpdate(e, setLoading) {
    try {
      await updateShift({
        id: e.row.original._id,
        data: e.data,
      }).unwrap();

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      console.error("Failed to update shift:", error);
      setLoading(false);
    }
  }

  // Only set shifts when the query is successful and data is available
  const shifts = isSuccess && data?.data?.shifts ? data.data.shifts : [];

  function handleClick() {
    navigate(`/shifts/create`);
  }

  return (
    <div className="xl:col-span-6 col-span-8 max-h-[450px]">
      <Table
        scrollable
        // actions={<Button title="Create Shift" onClick={handleClick} />}
        id="employee-shifts"
        title="Shifts"
        loading={isLoading}
        columns={COLUMN_DEFINITIONS}
        data={shifts}
        hideCheckbox
        updateData={handleUpdate}
      />
    </div>
  );
};

export default EmployeeShifts;
