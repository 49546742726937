import { format } from "date-fns";
import { getPdfConfig } from "./table.configs";
import {
  formatTimeWorked,
  renderAmount,
  formatPhoneNumber,
  getAppType,
} from "../../../utils";

// Helper function to check if new page is needed and create one if necessary
export const checkAndCreateNewPage = (
  doc,
  contentHeight,
  currentY,
  footerHeight = 20
) => {
  const pageHeight = doc.internal.pageSize.height;
  const marginBottom = footerHeight + 10; // Footer height plus some margin

  // Check if content will overflow current page
  if (currentY + contentHeight > pageHeight - marginBottom) {
    doc.addPage();
    // Reset Y position to top of new page with some margin
    return 20;
  }

  return currentY;
};

export const generateParentCompanyInfo = (doc, parentCompany) => {
  let currentY = 20;

  // Company Info
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(parentCompany?.name || "", 10, currentY);

  currentY += 10;

  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(`Phone: ${parentCompany?.phone || ""}`, 10, currentY);
  currentY += 5;
  doc.text(
    `Address: ${parentCompany?.address?.fullAddress || ""}`,
    10,
    currentY
  );
  currentY += 5;
  doc.text(`Email: ${parentCompany?.email || ""}`, 10, currentY);

  return { yPos: currentY };
};

export const generateCustomerInfo = (doc, info) => {
  let currentY = 55;

  doc.setFont("helvetica", "bold");
  doc.text("Bill To:", 14, currentY);
  currentY += 7;

  doc.setFont("helvetica", "normal");
  doc.text(customer?.name || "", 14, currentY);
  currentY += 7;

  doc.text(customer?.billingAddress?.street || "", 14, currentY);
  currentY += 7;

  doc.text(
    `${customer?.billingAddress?.city || ""}, ${
      customer?.billingAddress?.state || ""
    }`,
    14,
    currentY
  );
  currentY += 7;

  return { yPos: currentY };
};

// Generate work order information
export const generatePageInfo = (title, doc, info) => {
  let currentY = 15;

  // Title and ID
  doc.text(title, 120, currentY);
  currentY += 5;

  doc.setFont("helvetica", "bold");
  doc.text(info.jobId || info.id, 120, currentY);
  doc.setFont("helvetica", "normal");
  currentY += 5;

  // Date, Terms, and Due Date
  doc.text(
    `Date: ${format(new Date(info.date || new Date()), "MM/dd/yyyy")}`,
    120,
    currentY
  );
  currentY += 5;

  // Add terms and due date if they exist
  if (info.terms) {
    doc.text(`Terms: ${info.terms}`, 120, currentY);
    currentY += 5;
  }
  if (info.dueDate) {
    doc.text(
      `Due Date: ${format(new Date(info.dueDate), "MM/dd/yyyy")}`,
      120,
      currentY
    );
    currentY += 15;
  }

  // Customer Information
  doc.setFont("helvetica", "bold");
  doc.text("Bill To:", 120, currentY);
  doc.setFont("helvetica", "normal");
  currentY += 5;

  doc.text(`${info.name || ""}`, 120, currentY);
  currentY += 5;

  doc.text(`${info.address?.fullAddress || ""}`, 120, currentY);
  currentY += 5;

  doc.text(
    `${formatPhoneNumber(info.phone || "")}  ${info.email || ""}`,
    120,
    currentY
  );
  currentY += 5;

  return { yPos: currentY };
};

// New reusable function for generating totals
export const generateTotalsSection = (
  doc,
  items,
  finalY,
  highlightLastRow = true,
  parentCompany
) => {
  const totalsX = doc.internal.pageSize.width - 90;
  const totalHeight = items.length * 8; // Calculate total height needed
  let currentY = finalY;

  // Check if entire section needs to move to next page
  if (currentY + totalHeight > doc.internal.pageSize.height - 20) {
    // Generate footer on current page before adding new one
    const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
    generatePageFooter(doc, {
      companyName: parentCompany?.name,
      message: "Thank you for your business",
      pageNumber: currentPage,
    });

    doc.addPage();
    currentY = 20; // Reset to top of new page
  }

  items.forEach((item, index) => {
    const yPos = currentY;
    const isLastRow = index === items.length - 1;

    if (isLastRow && highlightLastRow) {
      doc.setDrawColor(56, 188, 164);
      doc.setFillColor(56, 188, 164);
      doc.roundedRect(totalsX - 5, yPos - 5, 85, 8, 2, 2, "F");
      doc.setTextColor(255, 255, 255);
    }

    doc.text(item.label, totalsX + 3, yPos);
    doc.text(
      item.type !== "amount" ? `${item.value}` : `$${item.value.toFixed(2)}`,
      doc.internal.pageSize.width - 20,
      yPos,
      { align: "right" }
    );

    doc.setTextColor(0, 0, 0);
    currentY += 8;
  });

  return { yPos: currentY };
};

const renderCell = (col, data) => {
  return {
    getValue: () =>
      col.id.split(".").reduce((obj, key) => obj?.[key], data) || 0,
  };
};

export const generateInvoiceCoverPage = (doc, invoice, appType) => {
  let currentY = 0;

  const { yPos: companyY } = generateParentCompanyInfo(
    doc,
    invoice.parentCompany
  );
  currentY = companyY;

  // Invoice Info
  const title = "Invoice Info";
  const { yPos: infoY } = generatePageInfo(title, doc, {
    address: invoice.client?.address,
    id: invoice.invoiceId,
    name: invoice.client?.name,
    phone: invoice.client?.phone,
    email: invoice.client?.email,
    date: invoice.date,
    terms: invoice.terms,
    dueDate: invoice.dueDate,
  });
  currentY = infoY;

  // Jobs Summary Table
  // Calculate margins to center the table
  const pageWidth = doc.internal.pageSize.width;
  const tableWidth = 190; // Total of all column widths (40+40+40+35+35)
  const margin = (pageWidth - tableWidth) / 2;

  const config = getPdfConfig(appType).pdfConfig;

  doc.autoTable({
    startY: 75,
    margin: { left: margin },
    head: [config.COLUMN_DEFINITIONS.map((col) => col.header)],
    body: invoice.jobs?.map((job) =>
      config.COLUMN_DEFINITIONS.map((col) => col.cell(renderCell(col, job)))
    ),
    theme: "striped",
    headStyles: {
      fillColor: [56, 188, 164],
      textColor: [255, 255, 255],
    },
  });

  currentY = doc.lastAutoTable.finalY + 10;

  const totalsItems = config.TOTAL_COLUMN_DEFINITIONS.map((col) => ({
    label: col.Header,
    type: col.type,
    value: col.cell({
      getValue: () =>
        col.id.split(".").reduce((obj, key) => obj?.[key], invoice) || 0,
    }),
  }));

  const { yPos: totalsY } = generateTotalsSection(doc, totalsItems, currentY);
  currentY = totalsY;

  // Add footer
  generatePageFooter(doc, {
    companyName: invoice.parentCompany?.name,
    message: "Thank you for your business",
  });

  return { yPos: currentY };
};

export const generateSingleItem = (doc, item, startY, options = {}) => {
  let currentY = startY;

  const { yPos: equipmentYPos } = generateSingleEquipmentSection(
    doc,
    item.equipment,
    currentY
  );
  currentY = equipmentYPos;

  const { yPos: itemDetailsYPos } = generateItemDetails(doc, item, currentY);
  currentY = itemDetailsYPos;

  const { yPos: partsYPos } = generateParts(doc, item, currentY, options);
  currentY = partsYPos;

  if (options.generateTotals) {
    const salesTax = (item.rates?.salesTaxRate / 100) * item.partsTotal;
    const totalsItems = [
      { label: "Parts Total:", value: renderAmount(item.partsTotal) || 0 },
      { label: "Hourly Total:", value: renderAmount(item.hourlyTotal) || 0 },
      {
        label: "Service Fee:",
        value: renderAmount(item.serviceFee) || 0,
      },
      {
        label: "Sales Tax:",
        value: renderAmount(salesTax) || 0,
      },
      {
        label: "Total:",
        value: renderAmount(item.totalCharges + salesTax) || 0,
      },
    ];

    const { yPos: totalsYPos } = generateTotalsSection(
      doc,
      totalsItems,
      currentY,
      true,
      options.parentCompany
    );
    currentY = totalsYPos;
  }

  return { yPos: currentY };
};

export const generateItemsSummary = (doc, items, startY, parentCompany) => {
  let currentY = startY;
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Services", 10, currentY);

  currentY += 5.8;

  for (let i = 0; i < items.length; i++) {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`Service ${i + 1}: ${items[i].serviceType}`, 10, currentY);
    doc.setFont("helvetica", "normal");

    let item = { ...items[i] };
    item.parentCompany = parentCompany;

    currentY += 3;

    const { yPos: itemY } = generateSingleItem(doc, item, currentY);
    currentY = itemY + 35;
  }

  return { yPos: currentY };
};

// Generate job details page
export const generateJobPage = (doc, jobData) => {
  let currentY = 0;

  const { yPos: companyY } = generateParentCompanyInfo(
    doc,
    jobData.parentCompany
  );
  currentY = companyY;

  // Work Order Info
  const title = "Work Order Info";
  const { yPos: infoY } = generatePageInfo(title, doc, {
    address: jobData.client?.address,
    id: jobData.jobId,
    name: jobData.client?.name,
    phone: jobData.client?.phone,
    email: jobData.client?.email,
    date: jobData.appointmentDate,
  });
  currentY = infoY;

  const appType = getAppType();
  const items = appType === "trucking" ? jobData.legs : jobData.items;

  const { yPos: itemsY } = generateItemsSummary(
    doc,
    items,
    currentY,
    jobData.parentCompany
  );
  currentY = itemsY;

  const totalsItems = [
    { label: "Parts Total:", value: renderAmount(jobData.partsTotal) || 0 },
    { label: "Hourly Total:", value: renderAmount(jobData.hourlyTotal) || 0 },
    {
      label: "Service Fee:",
      value: renderAmount(jobData.serviceFeeTotal) || 0,
    },
    { label: "Sales Tax:", value: renderAmount(jobData.salesTax) || 0 },
    {
      label: "Total:",
      value: renderAmount(jobData.calculatedTotalCharges) || 0,
    },
  ];

  const { yPos: totalsY } = generateTotalsSection(
    doc,
    totalsItems,
    doc.lastAutoTable.finalY + 10
  );
  currentY = totalsY;

  // Add footer after checking if a new page is needed
  const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
  generatePageFooter(doc, {
    companyName: jobData.parentCompany?.name,
    message: "Thank you for choosing our services",
  });

  // Check if we need to add a new page for additional content
  if (currentPage < doc.internal.getNumberOfPages()) {
    doc.addPage();
    currentY = 10;
  }

  return { yPos: currentY };
};

// Helper function to draw section boxes
export const drawSection = (doc, title, x, y, width, height) => {
  const radius = 3;
  doc.setDrawColor(200, 200, 200);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(x, y, width, height, radius, radius, "S");
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(title, x + 5, y + 10);
};

export const drawHorizontalLine = (doc, x, y, width, height) => {
  doc.setDrawColor(200, 200, 200);
  doc.line(x, y, x + width, y);
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
};

export const generateHeader = (doc, title, yPos, rightContent) => {
  let currentY = yPos;
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");

  // Left title
  doc.text(title, 15, currentY);

  // Right content if provided
  if (rightContent) {
    const pageWidth = doc.internal.pageSize.width;
    const rightWidth = doc.getTextWidth(rightContent);
    doc.text(rightContent, pageWidth - rightWidth - 15, currentY);
  }

  currentY += 5;
  drawHorizontalLine(doc, 15, currentY, 185, 1);

  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");

  return { yPos: currentY };
};

export const generateSingleEquipmentSection = (doc, equipment, yPos) => {
  let currentY = yPos;

  const equipmentDetails = [
    { label: "Make:", value: equipment?.manufacturer || "" },
    { label: "Model:", value: equipment?.model || "" },
    { label: "Serial:", value: equipment?.serialNumber || "" },
    { label: "Hour Meter:", value: equipment?.odometer || "" },
  ];

  doc.setDrawColor(200, 200, 200);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(10, currentY, 190, 25, 2, 2, "S"); // Reduced height from 30 to 20

  const { yPos: headerYPos } = generateHeader(
    doc,
    `Equipment Information${equipment?.name ? ` - ${equipment?.name}` : ""}${
      equipment?.type ? ` - ${equipment?.type}` : ""
    }`,
    currentY + 6
  );
  currentY = headerYPos;

  let xPos = 15;
  let detailYPos = currentY + 7;
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");

  equipmentDetails.forEach((detail) => {
    const label = detail.label;
    const value = `${detail.value}`;

    const textWidth = doc.getTextWidth(`${label} ${value}`);
    if (xPos + textWidth > 195) {
      xPos = 15;
      detailYPos += 9;
    }
    // doc.text(text, xPos, detailYPos);
    doc.setTextColor(128, 128, 128);
    doc.setFont("helvetica", "bold");
    doc.text(`${label}`, xPos, detailYPos);
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(`${value}`, xPos, detailYPos + 4);
    xPos += textWidth + 10;
  });

  currentY = detailYPos + 12;

  return { yPos: currentY };
};

// Generate equipment section
export const generateEquipmentSection = (doc, jobData) => {
  if (!jobData.items || jobData.items.length === 0) return { yPos: 0 };

  let currentY = 55;

  (jobData.items || []).forEach((item, index) => {
    const { yPos } = generateSingleEquipmentSection(
      doc,
      item.equipment,
      currentY
    );
    currentY = yPos + 10; // Increment yPos for next equipment card
  });

  return { yPos: currentY };
};

export const generateItemDetails = (doc, item, startY) => {
  let currentY = startY;

  // Calculate dimensions and spacing
  const pageWidth = doc.internal.pageSize.width;
  const margin = 15;
  const columnGap = 10;
  const singleColumnWidth = 180;
  const doubleColumnWidth = (pageWidth - 2 * margin - columnGap) / 2;
  const lineHeight = doc.getLineHeight() * 0.6;

  if (item.summary) {
    // First split text to check length
    const splitText = doc.splitTextToSize(
      item.summary || "",
      singleColumnWidth
    );

    // Calculate dynamic heights
    const headerHeight = lineHeight;
    const footerHeight = lineHeight * 4;
    let contentHeight = 0;

    if (splitText.length > 14) {
      const rewrappedText = doc.splitTextToSize(
        item.summary || "",
        doubleColumnWidth
      );
      const columnLines = Math.ceil(rewrappedText.length / 2);
      contentHeight = columnLines * lineHeight;
    } else {
      contentHeight = splitText.length * lineHeight;
    }

    // Calculate total height with minimal padding
    const verticalPadding = 8;
    const totalHeight =
      headerHeight + contentHeight + footerHeight + verticalPadding;

    // Check if we need a new page
    if (startY + totalHeight > doc.internal.pageSize.height - 20) {
      // Generate footer on current page before adding new one
      const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
      generatePageFooter(doc, {
        companyName: item.parentCompany?.name,
        message: "Thank you for your business",
        pageNumber: currentPage,
      });

      // Add new page after footer
      doc.addPage();

      currentY = 10; // Reset currentY for new page
      startY = currentY; // Reset startY to match

      // Add Service Details header on new page
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      currentY += 5;
      const { yPos: headerYPos } = generateHeader(
        doc,
        `Service Details`,
        currentY
      );
      currentY = headerYPos;
    } else {
      // Add header on same page
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      currentY += 5;
      const { yPos: headerYPos } = generateHeader(
        doc,
        `Service Details - ${item.serviceType}`,
        currentY
      );
      currentY = headerYPos;
    }

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    // Draw rectangle around the whole section
    doc.setDrawColor(200, 200, 200);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(10, startY - 2, 190, totalHeight, 2, 2, "S");

    // Start content positioning
    currentY += verticalPadding;

    // Draw header
    doc.setTextColor(128, 128, 128);
    doc.setFont("helvetica", "bold");
    doc.text("Summary:", 15, currentY - 2);
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");

    // Move to content start
    currentY += headerHeight / 2;

    // Track initial content Y position
    const contentStartY = currentY;

    if (splitText.length > 14) {
      // Use two columns with narrower width
      const rewrappedText = doc.splitTextToSize(
        item.summary || "",
        doubleColumnWidth
      );
      const midPoint = Math.ceil(rewrappedText.length / 2);
      const leftColumn = rewrappedText.slice(0, midPoint);
      const rightColumn = rewrappedText.slice(midPoint);

      // Print left column
      leftColumn.forEach((line) => {
        doc.text(line, margin, currentY);
        currentY += lineHeight;
      });

      // Calculate right column start Y
      let rightColumnY = contentStartY;

      // Print right column
      rightColumn.forEach((line) => {
        doc.text(line, margin + doubleColumnWidth + columnGap, rightColumnY);
        rightColumnY += lineHeight;
      });

      // Set currentY to the maximum height reached by either column
      currentY = Math.max(
        contentStartY + leftColumn.length * lineHeight,
        contentStartY + rightColumn.length * lineHeight
      );
    } else {
      // Print single column
      splitText.forEach((line) => {
        doc.text(line, margin, currentY);
        currentY += lineHeight;
      });
    }

    currentY += 8;

    // Add a horizontal line
    drawHorizontalLine(doc, 15, currentY, 185, 1);

    currentY += 7;

    // Technicians
    const technicians =
      item.performedBy
        ?.map((tech) => tech.user.fullName)
        .filter(Boolean)
        .join(", ") || "";

    // Left side - Technicians
    doc.setTextColor(128, 128, 128);
    doc.setFont("helvetica", "bold");
    const techLabel = "Technicians:";
    const techWidth = doc.getTextWidth(techLabel);
    doc.text(techLabel, 15, currentY);

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(`${technicians}`, 16 + techWidth, currentY);

    // Right side - Total Hours
    doc.setTextColor(128, 128, 128);
    doc.setFont("helvetica", "bold");
    const hoursLabel = "Total Labor Hours:";
    const hoursWidth = doc.getTextWidth(hoursLabel);
    doc.text(hoursLabel, doc.internal.pageSize.width - 60, currentY);

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${formatTimeWorked(item[item.selectedHourType]).display}`,
      doc.internal.pageSize.width - 58 + hoursWidth,
      currentY
    );

    currentY += 4;
  } else {
    currentY += lineHeight;
  }

  return { yPos: currentY };
};

// Generate service details section
export const generateServiceDetails = (doc, jobData, startY) => {
  let currentY = startY;

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Services", 10, currentY);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);

  currentY += 5;

  // Service summary for each maintenance item
  jobData.items?.forEach((item, index) => {
    // Parts Table and Totals
    const { yPos: partsY } = generateParts(doc, item, currentY);
    currentY = partsY;

    const totalsItems = [
      { label: "Parts Total:", value: renderAmount(jobData.partsTotal) || 0 },
      { label: "Hourly Total:", value: renderAmount(jobData.hourlyTotal) || 0 },
      {
        label: "Service Fee:",
        value: renderAmount(jobData.serviceFeeTotal) || 0,
      },
      { label: "Sales Tax:", value: renderAmount(jobData.salesTax) || 0 },
      {
        label: "Total:",
        value: renderAmount(jobData.calculatedTotalCharges) || 0,
      },
    ];

    const { yPos: totalsY } = generateTotalsSection(
      doc,
      totalsItems,
      doc.lastAutoTable.finalY + 10
    );
    currentY = totalsY + 10;
  });

  return { yPos: currentY };
};

// Generate parts table and totals
export const generateParts = (doc, item, startY, options = {}) => {
  let currentY = startY;

  // Calculate margins to center the table
  const pageWidth = doc.internal.pageSize.width;
  const tableWidth = 190; // Adjust this based on your column widths
  const margin = (pageWidth - tableWidth) / 2;

  // Calculate total of all parts if needed
  const partsTotal = options.generateTotals
    ? (item.parts || []).reduce((sum, part) => {
        return sum + part.quantity * (part.unitPrice || 0);
      }, 0)
    : 0;

  const headers = options.generateTotals
    ? ["Description", "Part Number", "Qty", "Price", "Total"]
    : ["Description", "Part Number", "Qty"];

  const columnStyles = options.generateTotals
    ? {
        0: { cellWidth: 50 }, // Description
        1: { cellWidth: 40 }, // Part Number
        2: { cellWidth: 30 }, // Qty
        3: { cellWidth: 35 }, // Price
        4: { cellWidth: 35 }, // Total
      }
    : {
        0: { cellWidth: 70 }, // Description
        1: { cellWidth: 60 }, // Part Number
        2: { cellWidth: 60 }, // Qty
      };

  doc.autoTable({
    startY: currentY + 5,
    margin: { left: margin },
    head: [headers],
    body: [
      ...(item.parts || []).map((part) => {
        const row = [
          part.part?.name || part.part?.description || "N/A",
          part.part?.partNumber || "N/A",
          part.quantity || "0",
        ];
        if (options.generateTotals) {
          row.push(
            `$${part.unitPrice?.toFixed(2) || "0.00"}`,
            `$${(part.quantity * (part.unitPrice || 0)).toFixed(2)}`
          );
        }
        return row;
      }),
      // Add totals row only if generateTotals is true
      ...(options.generateTotals
        ? [["", "", "", "Total:", `$${partsTotal.toFixed(2)}`]]
        : []),
    ],
    theme: "grid",
    headStyles: {
      fillColor: [56, 188, 164],
      textColor: [255, 255, 255],
    },
    styles: {
      fontSize: 10,
    },
    columnStyles,
    // Style the totals row if present
    didParseCell: function (data) {
      if (
        options.generateTotals &&
        data.row.index === data.table.body.length - 1
      ) {
        data.cell.styles.fontStyle = "bold";
      }
    },
  });

  currentY = doc.lastAutoTable.finalY + 10;

  return { yPos: currentY };
};

export const generatePageFooter = (doc, pageInfo = {}) => {
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  // Footer text - Set gray color only for footer
  doc.setFontSize(8);
  doc.setTextColor(128, 128, 128); // Set to gray for footer

  // Left side - Company name
  doc.text(pageInfo.companyName || "", 10, pageHeight - 10);

  // Center - Custom message
  const footerMessage = pageInfo.message || "Thank you for your business";

  // Check if this is the last page to avoid duplicate footer message
  if (
    doc.internal.getNumberOfPages() ===
    doc.internal.getCurrentPageInfo().pageNumber
  ) {
    doc.text(footerMessage, pageWidth / 2, pageHeight - 10, {
      align: "center",
    });
  }

  // Right side - Page number
  doc.text(
    `Page ${doc.internal.getNumberOfPages()}`,
    pageWidth - 20,
    pageHeight - 10
  );

  // Reset text color back to black for the rest of the document
  doc.setTextColor(0, 0, 0);
};
