import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const employeesApi = api.injectEndpoints({
  tagTypes: ["Employees"],
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const params = new URLSearchParams({
          page,
          limit,
          ...rest,
        });
        return `employees?${params}`;
      },
      transformResponse: (response) => response.data,
      providesTags: (data) =>
        data?.employees
          ? [
              ...data.employees.map(({ _id }) => ({
                type: "Employees",
                id: _id,
              })),
              { type: "Employees", id: "LIST" },
            ]
          : [{ type: "Employees", id: "LIST" }],
    }),

    getEmployee: builder.query({
      query: ({ id, ...rest }) => `employees/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => [
        { type: "Employees", id: data.id },
      ],
    }),

    createEmployee: builder.mutation({
      query(body) {
        return {
          url: "employees",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        { type: "Employees", id: "LIST" },
        { type: "Users", id: "LIST" },
      ],
    }),

    updateEmployee: builder.mutation({
      query({ id, data }) {
        return {
          url: `/employees/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Employees", id },
        { type: "Employees", id: "LIST" },
      ],
    }),

    createUser: builder.mutation({
      query({ id, body }) {
        return {
          url: `/employees/${id}/user`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Employees", id },
        { type: "Employees", id: "LIST" },
      ],
    }),

    deleteEmployee: builder.mutation({
      query({ data }) {
        return {
          url: `/employees`,
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { data }) => [
        ...data.map((id) => ({
          type: "Employees",
          id,
        })),
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useCreateUserMutation,
} = employeesApi;
