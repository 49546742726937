import React from "react";
import { Card, Avatar, Badge, EditableText } from "../../../shared";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
// import EmployeeInfoEditable from "./EmployeeInfoEditable";
import { renderAmount } from "../../../utils";

const EmployeeCard = ({ info, handleUpdate }) => {
  async function onSave(e, type) {
    let data = { ...e.data };
    if (type === "meta") {
      data = { meta: e.data };
    }
    handleUpdate(data);
  }

  const shortOrg = info.organization;

  return (
    <Card className="col-span-8 xl:col-span-2 p-6">
      <div className="flex xl:flex-col items-center justify-around my-4">
        <Avatar src={info.avatar} />
        <div className="my-4">
          <EmployeeInfoEditable
            id="email"
            icon={EnvelopeIcon}
            value={info.user?.email || info.email}
            save={(e) => onSave(e)}
          />
          <EmployeeInfoEditable
            id="phone"
            icon={PhoneIcon}
            value={info.user?.phone || info.phone}
            save={(e) => onSave(e)}
          />
        </div>
      </div>
      <hr />
      <div className="">
        <div className="grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-2 my-4 justify-items-start gap-3">
          <div>
            <h3 className="text-gray-500">Department</h3>
            <EmployeeInfoEditable
              id="department"
              value={info.meta?.department || info.department}
              save={(e) => onSave(e, "meta")}
              meta={true}
            />
          </div>
          <div>
            <h3 className="text-gray-500">Role</h3>
            <EmployeeInfoEditable
              id="role"
              value={info.user?.role || info.role}
              save={(e) => onSave(e, "meta")}
              meta={true}
            />
          </div>
          <div>
            <h3 className="text-gray-500">Hourly Rate</h3>
            <EmployeeInfoEditable
              displayText={(e) => renderAmount(e)}
              id="hourlyRate"
              value={info.meta?.hourlyRate}
              save={(e) => onSave(e, "meta")}
              meta={true}
              type="number"
            />
          </div>
          <div>
            <h3 className="text-gray-500">Organization</h3>
            <div>
              {shortOrg && (
                <Badge
                  label={shortOrg?.name}
                  link={`/organizations/${shortOrg?._id}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const EmployeeInfoEditable = ({ id, value, save, icon, type, displayText }) => {
  const Icon = icon;
  return (
    <div className="flex items-center">
      {icon && <Icon className="h-4 w-4 mr-1" />}
      <EditableText
        displayText={(e) => (
          <span className="text-base">{displayText ? displayText(e) : e}</span>
        )}
        id={id}
        iconClasses="h-3 w-3"
        value={value}
        onSave={(e) => save(e)}
        type={type}
      />
    </div>
  );
};

export default EmployeeCard;
